<template>
  <div>
    <h3 class="txt-heading txt-center mb-4"><strong>Welcome Back!</strong></h3>
    
    <b-form @submit.prevent="onSubmit">
      <form-control-text
        class="mb-3 mt-4"
        :state="$v.form.email.$error ? false : null"
        @change="$v.form.email.$touch()"
        id="loginEmail"
        placeholder="Email address"
        label="Email"
        required
        type="email"
        v-model="form.email" />
      <form-control-text
        :state="$v.form.password.$error ? false : null"
        @change="$v.form.password.$touch()"
        id="loginPass"
        placeholder="Password"
        label="Password"
        required
        type="password"
        v-model="form.password"
        showPasswordCheck />
      <b-button to="/login/forgot" size="sm" variant="link px-0" class="ml-auto">Forgot password?</b-button>
      <b-alert :show="!!error" fade variant="warning mb-0">
        <svg viewBox="0 0 24 24" class="alert-icon icon-sm"><use xlink:href="#icon-warning-triangle-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{error}}</span><br/>
          <a href="#" class="alert-link" @click.prevent="onReset()">
            <b>Try again?</b>
          </a>
        </div>
      </b-alert>

      <b-button id="loginBtn" :disabled="$v.$invalid || loading" block type="submit" variant="primary" class="mt-3 mb-2">
        Sign in
      </b-button>

      <hr class="or my-4" />

      <b-button :href="urlGoogle" :target="target" id="googleBtn" block variant="secondary" class="mt-2 mb-4">
        <svg class="btn-left" viewBox="0 0 24 24"><use xlink:href="#icon-google-logo"></use></svg>
        Sign in with Google
      </b-button>

    </b-form>
    <p class="mt-2 txt-body txt-center auth-page-cta with-arrow"><b>Don’t have an account?</b> <b-btn variant="link ml-1" :to="{name: 'Register'}">Sign Up for free!</b-btn></p>
  </div>
</template>


<script>
  import { email, required } from 'vuelidate/lib/validators';
  import { createNamespacedHelpers } from 'vuex';
  import get from 'lodash/get';
  import { emailFormatter, set } from '@app2/utils/helpers';
  import FormControlText from "@app2/core/Components/Common/Forms/Text";
  import subscriptionMixin from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.mixin.js";
  import Campaign from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.campaign.js";
  import axios from "@app2/api/axios";

  const { mapActions: mapAuthActions } = createNamespacedHelpers('auth');
  export default {
    name: 'Login',
    metaInfo() {
      return {
        title: 'Login',
      }
    },
    mixins: [subscriptionMixin],
    components: {FormControlText},
    props: {
      /** where to navigate after logged in */
      after: {
        type: Object,
        default: null,
      },
      logout: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      form: {
        email: '',
        password: '',
      },
      error: null,
      loading: null,
    }),
    validations: {
      form: {
        email: {
          required,
          email: (val) => email(emailFormatter(val)),
        },
        password: {
          required,
        },
      },
    },
    methods: {
      ...mapAuthActions(['login']),
      async onSubmit() {
        this.error = null;
        this.loading = true;
        try {
          const user = await this.login(this.form);
          if ( this.$route.query.promo_hash && this.subscriptionIsCancelled ) {
            const campaign = new Campaign(this.$route.query.promo_hash);
            await campaign.initialize();
            if(campaign.canCheckout) {
              campaign.updateUser(user);
              if ( campaign.isMultiplePlan ) {
                this.$router.push({name: 'OnboardingPlan', query: { query: this.$route.query }});
              } else {
                const shouldContinue = await campaign.checkout();
                if ( !shouldContinue ) return
                if (!get(this.$store, 'state.auth.user.is_onboard')) {
                  if(get(this.$store, 'state.auth.user.onboard_step') <= 1) {
                    this.$router.push({name: 'OnboardingDetails', query: this.$route.query});
                    return;
                  } else if(get(this.$store, 'state.auth.user.onboard_step') === 2) {
                    this.$router.push({name: 'OnboardingMigration', query: this.$route.query});
                    return;
                  }
                }
              }
            }
          }
          if (this.after) {
            const { name, params } = this.after;
            this.$router.push({ name, params });
          } else if (window.Searchie.intendedUrl) {
            location = window.Searchie.intendedUrl;
          } else {
            if ( this.$route.query.type === 'popup' ) {
              window.parent.postMessage({ action: 'logged-in-from-popup', origin: window.location.origin });
              return;
            }
            if ( this.$route.query.checkout ) this.$router.push({name: 'AccountSubscription', query: this.$route.query});
            else if ( this.$cantView('hubs') || this.$cant('audience') ) this.$router.push({name: 'library'});
            else this.$router.push({ name: 'library' }).catch(() => {});
          }
        } catch ({ data }) {
          if (data)
            this.error = get(data, 'errors.email[0]') || data.message;
        } finally {
          this.loading = null;
        }
      },
      onReset() {
        this.error = null;
        this.form.email = null;
        this.form.password = null;
        this.$v.$reset();
      },
    },
    computed: {
      urlGoogle() {
        return `${process.env.MIX_APP_URL}/social/google`;
      },
      target() {
        return this.$route.query.type === 'popup' ? '_blank' : '_self';
      }
    },
    created() {
      if (this.$store.getters['isTablet']) {
        const viewportEl = document.querySelector('head meta[content="width=1200"]');
        viewportEl.setAttribute('content', 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');
      }
    }
  };
</script>
