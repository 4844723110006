import multiguard from 'vue-router-multiguard';
import {subscribedGuard} from '@app2/router/guards';
import {onboardGuard} from '@app2/router/guards/onboarding';

export default [
  {
    path: 'data',
    name: 'AccountData',
    component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/Data/Pages/Index'),
    beforeEnter: multiguard([onboardGuard, subscribedGuard]),
    props: true,
    redirect: {
      name: 'AccountTags',
      params: {type: "files"}
    },
    children: [
      {
        path: 'attributes',
        name: 'AccountAudienceAttributes',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/Data/Pages/Attributes'),
        beforeEnter: onboardGuard,
        meta: {
          parent: 'AccountData'
        }
      },
      {
        path: 'tags/:type',
        name: 'AccountTags',
        component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/Data/Pages/Tags'),
        beforeEnter: onboardGuard,
        meta: {
          parent: 'AccountData'
        }
      },
    ],
    meta: {
      nav: 'Options',
      hideAddMedia: true
    }
  },
];
