<template>
  <div class="table-bulk" :class="{'selected': countSelected || selectAll, disabled: loading, empty: empty}">
    <template v-if="!empty">
      <b-form-checkbox :disabled="loading" class="mr-2" v-model="selectAll" :indeterminate="!!(countSelected && !selectAll)" :key="countSelected" />
      <span v-if="!loading" class="txt-heading-small txt-muted mb-0">
        <transition name="fade" mode="out-in">
          <span v-if="!countSelected">
            <b class="txt-dark">{{ countTotal | withCommas }}</b>
            {{ countTotal === 1 ? 'Person' : 'People' }}
          </span>
          <span v-else>
            <!--<b class="txt-white">{{ selectAll ? countTotal : countSelected }} Selected</b>-->
            <b class="txt-white">{{ countSelected | withCommas }} Selected</b>
            out of {{ countTotal | withCommas }} {{ countTotal === 1 ? 'Person' : 'People' }}
          </span>
        </transition>
      </span>
      <span class="txt-muted" v-else>Loading...</span>
    </template>
    <transition name="fade" mode="out-in">
      <div class="slot-right" v-if="countSelected || selectAll">
        <b-btn variant="platform-light" @click.stop="openBulkDialog" class="ml-auto">
          <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-pencil"></use></svg>
          Edit
        </b-btn>
        <b-btn variant="platform-light" @click.stop="openExportAudienceDialog" class="ml-2">
          <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-upload"></use></svg>
          Export
        </b-btn>
        <hr class="vertical vertical-light">
        <b-btn variant="platform-light" @click.stop="openBulkDeleteDialog">
          <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-trash"></use></svg>
        </b-btn>
        <!-- <b-btn id="audience-table-exports" :variant="countSelected || selectAll ? 'primary' : 'icon primary'" @click.stop="">
          <svg viewBox="0 0 24 24" class="icon-sm"><use xlink:href="#icon-more"></use></svg>
        </b-btn> -->
        <!-- <b-popover ref="dd" placement="leftbottom" target="audience-table-exports" triggers="click blur">
          <div class="dropdown-cont sm">
            <b-dropdown-item @click.stop="openExportAudienceDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-upload"></use></svg>
              Export Audience
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click.stop>
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-clock"></use></svg>
              Recent Exports
            </b-dropdown-item>
            <b-dropdown-item @click.stop>
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-clock"></use></svg>
              Recent Imports
            </b-dropdown-item>
          </div>
        </b-popover> -->
      </div>
      <div class="slot-right" v-else>
        <audience-search
          :disabled="loading"
          @sync="$emit('sync')"/>
        <b-btn ref="audience-table-imports" variant="icon primary ml-2" :disabled="loading" @click.stop="">
          <svg viewBox="0 0 24 24" width="24"><use xlink:href="#icon-more"></use></svg>
        </b-btn>
        <b-popover ref="dd" placement="leftbottom" :target="() => $refs['audience-table-imports']" triggers="click blur">
          <div class="dropdown-cont sm">
            <b-dropdown-item :disabled="loading" @click.stop="openExportAudienceDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-upload"></use></svg>
              Export Audience
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :disabled="loading" @click.stop="openRecentExportDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-clock"></use></svg>
              Recent Exports
            </b-dropdown-item>
            <b-dropdown-item :disabled="loading" @click.stop="openRecentImportDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-clock"></use></svg>
              Recent Imports
            </b-dropdown-item>
          </div>
        </b-popover>
      </div>
    </transition>
  </div>
</template>
<script>
  import rootVue from "@app2/app";
  import importRouter from '@app2/modules/Audience/Import/import.router'
  import exportRouter from '@app2/modules/Audience/Export/export.router'
  import bulkRouter from '@app2/modules/Audience/Bulk/bulk.router'
  import AudienceSearch from "@app2/modules/Audience/Listing/Components/AudienceSearch";
  export default {
    name: 'AudienceTableHead',
    components: {
      AudienceSearch,
    },
    props: {
      countTotal: {
        type: Number,
        default: 0,
      },
      countSelected: {
        type: Number,
        default: 0,
      },
      loading: Boolean,
      empty: Boolean
    },
    computed: {
      selectAll: {
        get() { return this.$store.state.v2.audience.selectAll },
        set(val) { this.$emit('selectAll', val) }
      }
    },
    methods: {
      openExportAudienceDialog() {
        exportRouter.push({name: 'ExportIndex'}).catch(() => {})
        rootVue.$bvModal.show('export-dialog');
      },
      openRecentImportDialog() {
        importRouter.push({name: 'RecentImports'}).catch(() => {})
        rootVue.$bvModal.show('import-dialog');
      },
      openRecentExportDialog() {
        exportRouter.push({name: 'RecentExports'}).catch(() => {})
        rootVue.$bvModal.show('export-dialog');
      },
      openBulkDialog() {
        bulkRouter.push({name: 'BulkIndex'}).catch(() => {})
        rootVue.$bvModal.show('bulk-dialog')
      },
      openBulkDeleteDialog() {
        rootVue.$bvModal.show('bulk-delete-dialog')
      }
    }
  }
</script>
