var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: "person-editor-dialog",
        "modal-class": "modal-right person-dialog",
        "body-bg-variant": "white",
        static: "",
        "hide-footer": "",
        "hide-header": ""
      },
      on: { hide: _vm.onHide, show: _vm.onShow },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [_c("div", { attrs: { id: "person-app" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }