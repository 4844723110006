<template>
  <b-modal :id="id" :static="static" centered ref="modal" size="sm">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-primary">{{ title }}</h5>
      <b-button variant="icon" size="sm" @click="close()" class="close">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>
    <form @submit.prevent="$emit('accept', model)">
      <b-form-input
        class="mb-4"
        size="lg"
        v-model="model"
        ref="input"
        :placeholder="placeholder"
        :state="$v.model.$error ? false : null" />
      <b-alert show variant="warning" v-if="alert">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-warning-triangle-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">This action will not retain audience access or data.</span>
        </div>
      </b-alert>
    </form>
    <template slot="modal-footer">
      <b-btn :disabled="processing" @click="$bvModal.hide(id)" variant="secondary">
        Cancel
      </b-btn>
      <b-btn
        :disabled="processing || $v.model.$error"
        @click="$emit('accept', value)"
        variant="primary"
      >
        Duplicate
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
  import {required} from "vuelidate/lib/validators";

  export default {
    name: 'DuplicateDialog',
    computed: {
      model: {
        get() {
          setTimeout(() => {
            if(this.$refs.input) this.$refs.input.focus()
          }, 100)
          return this.value
        },
        set(val) {
          this.$emit('input', val)
          if (this.$v && this.$v.model) this.$v.model.$touch();
        }
      }
    },
    props: {
      id: {},
      alert: {
        type: Boolean,
        default: true
      },
      title: {},
      value: {},
      processing: {},
      placeholder: {},
      static: {
        type: Boolean,
        default: true
      },
    },
    validations: {
      model: {
        required
      }
    }
  };
</script>
