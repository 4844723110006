import multiguard from "vue-router-multiguard";
import get from 'lodash/get';
import store from "@app2/store";
import eventBus from "@app2/core/eventBus";
import app from "@app2/app";
import { hasPermission } from "@app2/utils/filters";
import { onboardGuard } from "@app2/router/guards/onboarding.js";

function isUndefined(value) {
  return value === undefined;
}

function evaluateGuards(guards, to, from, next) {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
      next();
      return;
  }

  nextGuard(to, from, (nextArg) => {
      if (isUndefined(nextArg)) {
          evaluateGuards(guardsLeft, to, from, next);
          return;
      }

      next(nextArg);
  });
}

export const manualMultiguard = (to, from, next) => {
  return evaluateGuards([onboardGuard, subscribedGuard, dashboardGuard], to, from, next);
}

export const subscribedGuard = (to, from, next) => {
  let subscription = store.getters['auth/GET_SUBSCRIPTION'];
  if (store.state.auth.subscribed || get(window.Spark, 'state.currentTeam.subscribed', false) || (subscription && subscription.status === 'active')) {
    next();
  } else {
    next({ name: 'AccountSubscription' });
  }
};

export const dashboardGuard = (to, from, next) => {
  if ( to.name === 'dashboard' && !hasPermission('hubs', null, { viewOnly: true }) ) {
    next({ name: 'library' });
  } else {
    next();
  }
};

export const addGuardstoRoutes = routes => {
  return routes.map(r => {
    if (r.children) {
      r.children = r.children.map(child => {
        child.beforeEnter = multiguard([subscribedGuard, onboardGuard]);
        return child
      })
    }
    r.beforeEnter = multiguard([subscribedGuard, onboardGuard]);
    r.props = true;
    return r;
  });
}

export const affiliateGuard = (to, from, next) => {
  // TODO: change this in the future
  if (!!get(window.Spark, 'state.currentTeam.meta.affiliates.auth_token', false)) {
    next();
  } else {
    next('/');
  }
};

export const checkEditors = routes =>
  routes.map(r => {
    r.beforeEnter = (to, from, next) => {
      if ( store.state.v2.modals.widget || store.state.v2.modals.transcript || store.state.v2.modals.wisdom ) {
        if ( store.state.v2.modals.widget && store.state.v2.editors.widget ) {
          store.commit('v2/STORE_TEMP_ROUTE', to)
          eventBus.$emit('checkWidgetEditor')
          next(false);
        } else if (store.state.v2.modals.widget) {
          app.$bvModal.hide('widget-editor');
          next();
        }
        if ( store.state.v2.modals.wisdom && store.state.v2.editors.wisdom ) {
          store.commit('v2/STORE_TEMP_ROUTE', to)
          eventBus.$emit('checkWisdomEditor')
          next(false);
        } else if (store.state.v2.modals.wisdom) {
          app.$bvModal.hide('wisdom-editor');
          next();
        }
        if ( store.state.v2.modals.transcript && store.state.v2.editors.transcript ) {
          store.commit('v2/STORE_TEMP_ROUTE', to)
          eventBus.$emit('checkTranscriptEditor')
          next(false);
        } else if (store.state.v2.modals.transcript) {
          app.$bvModal.hide('transcript-editor');
          next();
        }
      } else {
        next();
      }
    };
    return r;
  });