var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: { id: _vm.id, static: _vm.static, centered: "", size: "sm" },
      on: {
        hidden: function($event) {
          return _vm.$emit("hidden")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c("h5", { staticClass: "txt-body txt-red-3" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "close",
                  attrs: { variant: "icon warning", size: "sm" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "icon-sm",
                      attrs: { width: "24", viewBox: "0 0 24 24" }
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
                  )
                ]
              )
            ]
          }
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.resourceName && _vm.extraInfo
        ? _c(
            "div",
            {
              class: _vm.$store.getters["isMobile"]
                ? "txt-body"
                : "txt-heading-small"
            },
            [_vm._v(_vm._s(_vm.extraInfo))]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.resourceName
        ? _c(
            "div",
            {
              class: _vm.$store.getters["isMobile"]
                ? "txt-body"
                : "txt-heading-small"
            },
            [
              _vm._v("Are you sure want to " + _vm._s(_vm.action) + " "),
              _c("b", [_vm._v('"' + _vm._s(_vm.resourceName) + '"')]),
              _vm._v("?")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { variant: "warning", disabled: _vm.processing },
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide(_vm.id)
                }
              }
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              attrs: {
                disabled: _vm.disabled || _vm.processing,
                variant: "danger"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("accept")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.deleteButtonLabel) + "\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }