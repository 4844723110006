import get from 'lodash/get';
import { set } from "@app2/utils/helpers";

import editor from '@app2/modules/Hubs/Editor/store';
import settings from '@app2/modules/Hubs/Settings/settings.store';

import AudienceAttribute from '@app2/models/AudienceAttribute';

const namespaced = true;

const state = {
  defaultContent: {},
  selectedEmailTemplate: null,
  emailTemplates: [],
  cssInsert: null,
  personalisation: {
    label: 'Personalisation',
    options: [
      {
        title: 'User Name',
        code: '{{ name }}'
      },
      {
        title: 'User First Name',
        code: '{{ first_name }}'
      },
      {
        title: 'User Last Name',
        code: '{{ last_name }}'
      },
      {
        title: 'User Email',
        code: '{{ email }}'
      }
    ],
  },
  recentlyWatchedMedia: null,
  duplicating: false,
  copyingFromTemplate: false,
};

const mutations = {
  STORE_DEFAULT_CONTENT(state, payload) {
    state.defaultContent = payload
  },
  STORE_DEFAULT_FILE(state, payload) {
    set(state, 'defaultContent.file', payload);
  },
  STORE_DEFAULT_PLAYLIST(state, payload) {
    set(state, 'defaultContent.playlist', payload);
  },
  SET_SELECTED_EMAIL_TEMPLATE(state, payload) {
    state.selectedEmailTemplate = JSON.parse(JSON.stringify(payload))
  },
  SET_EMAIL_TEMPLATES(state, payload) {
    payload = payload.map(t => {
      delete t.hash;
      return t;
    })
    state.emailTemplates = payload;
  },
  SET_RECENTLY_WATCHED(state, payload) {
    state.recentlyWatchedMedia = payload
  },
  SET_DUPLICATING(state, payload) {
    state.duplicating = payload
  },
  SET_COPYING_FROM_TEMPLATE(state, payload) {
    state.copyingFromTemplate = payload
  },
  SET_CSS_INSERT(state, payload) {
    state.cssInsert = payload
  },
  STORE_ATTRIBUTES(state, payload) {
    state.attributes = payload
  },
};

const actions = {
};

const modules = {
  editor,
  settings,
};

const getters = {
  defaultContent: state => state.defaultContent,
  personalisation: (state, getters, rootState) => {
    let personalisation = JSON.parse(JSON.stringify(state.personalisation));
    let attributes = AudienceAttribute.query().whereIdIn(rootState.v2.hubs.editor.attributes).get();
    if (attributes.length) {
      personalisation.options.push({
        code: 'attributes',
        title: 'Attributes',
        options: attributes.map(attribute => {
          return {
            title: attribute.title,
            type: attribute.type,
            code: `{{ attribute-${attribute.id} }}`,
          }
        }),
        create: {
          label: 'New Attribute',
          route: {
            name: 'Attributes'
          }
        }
      });
    }
    return personalisation;
  },
  defaultFile: state => get(state, 'defaultContent.file'),
  defaultPlaylist: state => get(state, 'defaultContent.playlist'),
  selectedEmailTemplate: state => state.selectedEmailTemplate,
  emailTemplate: (state) => (id) => state.emailTemplates.find(t => t.type === id),
  recentlyWatchedMedia: state => state.recentlyWatchedMedia,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
  modules,
};
