<template>
  <div>
    <h3 class="txt-heading txt-center mb-4 pb-4"><b>Forgot Password?</b></h3>
    <b-form @submit.prevent.stop="onSubmit">
      <form-control-text
        :state="$v.form.email.$error ? false : null"
        @change="$v.form.email.$touch()"
        id="loginEmail"
        label="Email"
        placeholder="Enter email address..."
        required
        type="email"
        v-model="form.email" />
      <b-btn :disabled="$v.$invalid || loading" block type="submit" variant="primary" class="my-4">Send reset email</b-btn>
      <b-alert :show="!!error" fade variant="error" class="mb-3">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{ error }} <a @click.prevent="onReset()" class="alert-link">Try again?</a></span>
        </div>
      </b-alert>
      <b-alert class="mb-0" variant="error" :show="!!error && showError">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{ error }}</span>
        </div>
      </b-alert>

      <b-alert show fade variant="info">
        <svg width="24" class="alert-icon icon-sm" viewBox="0 0 24 24"><use xlink:href="#icon-status-question-color"></use></svg>
        <div class="alert-content">
          <p class="txt-body mb-3" v-if="!prefilled"><b>Enter the email address you used when you signed up and we’ll send you instructions to reset your password.</b></p>
          <p class="txt-body">For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.</p>
        </div>
      </b-alert>
      <b-btn @click="$router.push({name: 'login'})" :disabled="loading" block variant="secondary" class="my-4">
        <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-arrow-left"></use></svg>
        Back to Login
      </b-btn>
      <p class="mt-2 txt-body txt-center txt-muted auth-page-cta"><b>Don’t have an account?</b> <b-btn variant="link ml-1" :to="{name: 'Register'}">Sign Up for free!</b-btn></p>
    </b-form>
  </div>
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';
import { emailFormatter } from '@app2/utils/helpers';
import FormControlText from "@app2/core/Components/Common/Forms/Text";

const { mapActions: mapAuthActions } = createNamespacedHelpers('auth');
export default {
  name: 'ForgotPassword',
  components: {FormControlText },
  data: () => ({
    form: {
      email: '',
    },
    token: null,
    error: null,
    loading: null,
    prefilled: false,
  }),
  validations: {
    form: {
      email: {
        required,
        email: (val) => email(emailFormatter(val)),
      },
    },
  },
  created() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email
      this.prefilled = true;
    }
  },
  metaInfo() {
    return {
      title: 'Forgot Password',
    }
  },
  methods: {
    ...mapAuthActions(['forgotPassword']),
    async onSubmit() {
      this.error = null;
      this.loading = true;
      try {
        await this.forgotPassword(this.form.email);
        this.$toasted.show(`An email has been sent to ${this.form.email}`, { type: 'success', position: 'bottom-right' });
      } catch ({ data }) {
        this.error = get(data, 'errors.email.0', data.message);
      } finally {
        this.loading = null;
      }
    },
    onReset() {
      this.error = null;
      this.form.email = null;
      this.form.password = null;
      this.prefilled = false;
      this.$v.$reset();
    }
  },
};
</script>
