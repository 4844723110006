var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-avatar mt-4 mb-3",
      class: ((_obj = {}), (_obj["size-" + _vm.size] = true), _obj)
    },
    [
      _vm.model && !_vm.error
        ? _c("img", {
            attrs: { src: _vm.model },
            on: {
              error: function($event) {
                _vm.error = true
              }
            }
          })
        : _c(
            "b-avatar",
            {
              attrs: { variant: _vm.variant, src: _vm.model, rounded: "" },
              on: {
                "img-error": function($event) {
                  _vm.error = true
                }
              }
            },
            [
              false
                ? _c(
                    "svg",
                    {
                      staticClass: "m-auto",
                      attrs: {
                        width: "148",
                        height: "148",
                        viewBox: "0 0 148 148",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("circle", {
                        attrs: { cx: "74", cy: "74", r: "70", fill: "#D9D9D9" }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          "vector-effect": "non-scaling-stroke",
                          d:
                            "M74.3715 77.7391C88.2943 77.7391 99.5809 66.4525 99.5809 52.5297C99.5809 38.6069 88.2943 27.3203 74.3715 27.3203C60.4487 27.3203 49.1621 38.6069 49.1621 52.5297C49.1621 66.4525 60.4487 77.7391 74.3715 77.7391Z",
                          stroke: "#707070",
                          "stroke-width": "1.5",
                          "stroke-miterlimit": "10",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          "vector-effect": "non-scaling-stroke",
                          d:
                            "M117.965 128.648L112.545 109.431C111.105 102.949 105.342 98.627 98.86 98.627H48.4412C41.9588 98.627 36.1966 102.949 34.7561 109.431L29.3359 128.648",
                          stroke: "#707070",
                          "stroke-width": "1.5",
                          "stroke-miterlimit": "10",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "svg",
                {
                  staticClass: "m-auto w-100",
                  attrs: {
                    width: "148",
                    height: "149",
                    viewBox: "0 0 148 149",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c(
                    "g",
                    { attrs: { "clip-path": "url(#clip0_userSettings)" } },
                    [
                      _c("circle", {
                        attrs: {
                          cx: "74",
                          cy: "74.8281",
                          r: "70",
                          fill: "#D9D9D9"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "M74.3715 78.5672C88.2943 78.5672 99.5809 67.2806 99.5809 53.3578C99.5809 39.4351 88.2943 28.1484 74.3715 28.1484C60.4487 28.1484 49.1621 39.4351 49.1621 53.3578C49.1621 67.2806 60.4487 78.5672 74.3715 78.5672Z",
                          stroke: "#707070",
                          "stroke-width": "1.5",
                          "stroke-miterlimit": "10",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d:
                            "M117.965 129.474L112.545 110.257C111.105 103.775 105.342 99.4531 98.86 99.4531H48.4412C41.9588 99.4531 36.1966 103.775 34.7561 110.257L29.3359 129.474",
                          stroke: "#707070",
                          "stroke-width": "1.5",
                          "stroke-miterlimit": "10",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("rect", {
                    attrs: {
                      x: "3.5",
                      y: "4.32812",
                      width: "141",
                      height: "141",
                      rx: "70.5",
                      stroke: "black",
                      "stroke-opacity": "0.05"
                    }
                  }),
                  _vm._v(" "),
                  _c("defs", [
                    _c("clipPath", { attrs: { id: "clip0_userSettings" } }, [
                      _c("rect", {
                        attrs: {
                          x: "4",
                          y: "4.82812",
                          width: "140",
                          height: "140",
                          rx: "70",
                          fill: "white"
                        }
                      })
                    ])
                  ])
                ]
              )
            ]
          ),
      _vm._v(" "),
      _vm.editable
        ? _c("div", { staticClass: "user-avatar-edit" }, [
            _c("input", {
              ref: "file",
              attrs: { type: "file", multiple: false },
              on: { input: _vm.update }
            }),
            _vm._v(" "),
            _c("svg", { attrs: { width: "24", viewBox: "0 0 24 24" } }, [
              _c("use", { attrs: { "xlink:href": "#icon-pencil" } })
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }