<template>
  <b-modal
    id="person-editor-dialog"
    modal-class="modal-right person-dialog"
    body-bg-variant="white"
    static
    ref="modal"
    hide-footer
    hide-header
    v-model="isModalOpen"
    @hide="onHide"
    @show="onShow"
  >
    <div id="person-app"></div>
  </b-modal>
</template>
<script>
  import App from '@app2/modules/Audience/Person/person.app';
  import router from "@app2/modules/Audience/Person/person.router"
  import rootVue from "@app2/app";
  import eventBus from '@app2/core/eventBus'

  export default {
    name: 'SegmentEditor',
    data() {
      return {
        app: null,
      }
    },
    computed: {
      bgVariant() {
        return this.$store.state.v2.audience.person.bgVariant;
      },
      isModalOpen: {
        get() { return this.$store.state.v2.audience.person.isModalOpen },
        set(val) {
          this.$store.commit('v2/audience/person/SET_MODAL_STATE', val);
          this.$store.commit('v2/audience/RESET_AUDIENCE_ATTRIBUTES', true);
        },
      },
      canCloseModal() {
        return this.$store.getters['v2/audience/person/canCloseModal']
      },
      form() {
        return this.$store.getters['v2/audience/person/getCreateForm']
      }
    },
    mounted() {
      this.app = App();
      this.$nextTick(() => {
        if ( !router.currentRoute.name ) router.push({name: 'Create'});
      });
    },
    methods: {
      onShow() {
        this.$store.commit('v2/audience/person/SET_FORM', new SparkForm({
          name: '',
          email: '',
          phone: '',
          hubs: [],
          tags: [],
          notify: false
        }))
        this.$store.commit('v2/audience/person/SET_FORM_INITIAL', JSON.stringify(this.form))
      },
      onHide(e) {
        if(!this.canCloseModal) {
          rootVue.$bvModal.show('exit-person-dialog')
          e.preventDefault();
        } else {
          this.$emit('close')
        }
      }
    }
  }
</script>
