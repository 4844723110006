import hubSettingsRoutes from '@app2/modules/Hubs/Settings/settings.router';

export default [
  {
    path: '/hubs',
    name: 'hubs',
    component: () => import(/* webpackChunkName: "hubs-v2" */ '@app2/modules/Hubs/Listing/Pages/Index'),
  },
  {
    path: '/hubs/templates',
    name: 'hubTemplates',
    component: () => import(/* webpackChunkName: "hubs-v2" */ '@app2/modules/Hubs/Templates/Pages/Index'),
  },
  {
    path: '/hubs/:hash',
    name: 'hub',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Dashboard/Pages/Index'),
  },
  {
    path: '/hubs/:hash/editor',
    name: 'hubEditor',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
    redirect: '/hubs/:hash/editor/pages/dashboard'
  },
  {
    path: '/hubs/:hash/editor/pages/dashboard',
    name: 'hubEditorDashboard',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
  },
  {
    path: '/hubs/:hash/editor/pages/:page',
    name: 'hubEditorPage',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Editor/Pages/Index'),
  },
  {
    path: '/hubs/:hash/audience',
    name: 'hubAudience',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Audience/Pages/Index'),
  },
  {
    path: '/hubs/:hash/settings',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Settings/Pages/Index'),
    name: 'hubSettings',
    children: hubSettingsRoutes
  },
  {
    path: '/hubs/:hash/payment',
    component: () => import(/* webpackChunkName: "hubs-v2" */'@app2/modules/Hubs/Payment/Pages/Index'),
    name: 'hubPayment'
  },
];
