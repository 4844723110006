import multiguard from 'vue-router-multiguard';
import {subscribedGuard} from '@app2/router/guards';
import {onboardGuard} from '@app2/router/guards/onboarding';

export default [
  {
    path: 'webhooks',
    name: 'AccountWebhooks',
    component: () => import(/* webpackChunkName: "v2-account-settings" */ '@app2/modules/Webhooks/Pages/Index'),
    beforeEnter: multiguard([onboardGuard, subscribedGuard]),
    props: true,
    meta: {
      nav: 'Options',
      hideAddMedia: true
    }
  },
];
