<template>
  <div class="form-control-textarea">
    <label v-if="label" class="form-control-label txt-body">{{ label }}</label>
    <span class="form-control-description txt-body" v-if="description">{{ description }}</span>
    <b-form-group
      ref="inputGroup"
      label-for="textarea"
    >
      <b-textarea
        id="textarea"
        v-model="model"
        :placeholder="placeholder"
        :readonly="readonly"
        :class="[{'is-invalid': !!error},inputClass]"
        :rows="rows"
        :max-rows="maxRows"
        :maxlength="maxlength"
        @input="$emit('input', $event)"
      />
    </b-form-group>
    <template v-if="hasManyErrors">
      <b-alert v-for="(err, key) in errorMessage" :key="key" class="mb-0 mt-3" variant="error" :show="!!error">
        <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
        <div class="alert-content">
          <span class="txt-body">{{ err }}</span>
        </div>
      </b-alert>
    </template>
    <b-alert v-else class="mb-0 mt-3" variant="error" :show="!!error">
      <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
      <div class="alert-content">
        <span class="txt-body">{{ errorMessage }}</span>
      </div>
    </b-alert>
  </div>
</template>
<script>
  export default {
    name: 'FormControlTextarea',
    props: {
      label: {
        type: String
      },
      description: {
        type: String
      },
      value: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
      },
      icon: {
        type: String,
      },
      type: {
        type: String,
        default: 'text'
      },
      state: {},
      error: {},
      errorMessage: {},
      formatter: {},
      disabledTooltip: {},
      readonly: Boolean,
      inputClass: String,
      rows: {},
      maxRows: {},
      maxlength: {},
    },
    computed: {
      model: {
        get() { return this.value },
        set(value) { this.$emit('input', value) },
      },
      hasManyErrors() {
        return this.errorMessage && Array.isArray(JSON.parse(JSON.stringify(this.errorMessage)))
      }
    }
  }
</script>
