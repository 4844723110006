<template>
  <b-modal :id="id" :static="static" centered ref="modal" size="sm" @shown="onShown" @hide="onHide" @hidden="resetModal">
    <template slot="modal-header" slot-scope="{ close }">
      <h5 class="txt-body txt-primary">{{ title }}</h5>
      <b-button variant="icon" size="sm" @click="close()" class="close">
        <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
      </b-button>
    </template>

    <p class="txt-body mb-3" v-if="description">{{ description }}</p>

    <b-form-input
      class="mb-2"
      v-model="model"
      ref="input"
      :placeholder="placeholder"
      :state="$v.model.$error && !hideError ? false : null"
      @keydown.enter="$emit('accept', value)"
    />
    <b-alert variant="error" :show="!!error">
      <svg class="alert-icon icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-error-color"></use></svg>
      <div class="alert-content">
        <span class="txt-body">{{ error }}</span>
      </div>
    </b-alert>
    <template slot="modal-footer">
      <b-btn :disabled="processing || $v.model.$error || !model || model === ''" variant="primary" block @click="$emit('accept', value)">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-plus-circle"></use></svg>
        {{ buttonLabel }}
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
  import {required} from "vuelidate/lib/validators";

  export default {
    name: 'CreateDialog',
    computed: {
      model: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', val)
          if (this.$v && this.$v.model) this.$v.model.$touch();
        }
      },
    },
    props: {
      title: {},
      description: {},
      buttonLabel: {
        type: String,
        default: 'Create'
      },
      placeholder: {
        type: String,
        default: 'Enter Name'
      },
      id: {},
      // resourceName: {},
      value: {},
      processing: {},
      error: {},
      static: {
        type: Boolean,
        default: true
      },
    },
    validations: {
      model: {
        required
      }
    },
    data() {
      return {
        hideError: false,
      }
    },
    methods: {
      resetModal () {
        this.model = ''
        this.$v.$reset()
      },
      onShown() {
        this.hideError = false;
        this.$nextTick(() => {
          if(this.$refs.input) this.$refs.input.focus()
        });
      },
      onHide() {
        this.hideError = true;
      }
    }
  };
</script>
