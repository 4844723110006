var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$cantView("audience")
    ? _c("upgrade-promo", {
        attrs: {
          description:
            "Invite your people to Hubs, segment them into functional groups, award achievements, and so much more!",
          link: _vm.redirect
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._v("\n    Manage Your entire Audience all in "),
                  _c("span", [_vm._v("One Place ")])
                ]
              },
              proxy: true
            },
            {
              key: "image",
              fn: function() {
                return [
                  _c("img", {
                    staticClass: "illustration-audience",
                    attrs: {
                      src:
                        _vm.$assetUrl +
                        "/images/hubs/illustration-audience-upgrade.png",
                      alt: ""
                    }
                  }),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "avatar-top",
                    attrs: {
                      src: _vm.$assetUrl + "/images/hubs/avatar-top.png",
                      alt: ""
                    }
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          false,
          1872076514
        )
      })
    : _c(
        "div",
        { staticClass: "audience-main" },
        [
          _c(
            "audience-header",
            {
              attrs: {
                loading: _vm.isLoading,
                "no-audience":
                  !_vm.audiences.length &&
                  !_vm.isLoading &&
                  !_vm.hasFilter &&
                  ((_vm.segment && !_vm.segment.conditionsCount) ||
                    !_vm.segment)
              },
              on: { reset: _vm.reset }
            },
            [
              _c(
                "p",
                {
                  staticClass: "txt-body txt-muted px-3 mb-3",
                  class: {
                    "invisible no-events":
                      _vm.isRouteChanged ||
                      (!_vm.isLoading && !_vm.audiences.length)
                  }
                },
                [
                  _vm._v(
                    "Your Audience contains everyone who has accessed your content in the past or may access it in the future. "
                  ),
                  _c("br"),
                  _vm._v(
                    " Add people to your audience one-by-one or import a list of people with a CSV file.\n      "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "txt-primary btn-link-text",
                      attrs: {
                        target: "_blank",
                        href: "https://help.searchie.io/the-audiences-tab"
                      }
                    },
                    [_vm._v("Learn more.")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.hasFilter ||
              (_vm.segment && _vm.segment.conditionsCount) ||
              (_vm.audiences.length && !_vm.isRouteChanged) ||
              _vm.isLoading
                ? _c("audience-table-head", {
                    attrs: {
                      "count-total": _vm.total,
                      "count-selected": _vm.selectedData.length,
                      loading: _vm.isLoading,
                      empty: !_vm.audiences.length && !_vm.isLoading
                    },
                    on: {
                      "filter-changed": _vm.getAudienceApi,
                      selectAll: function($event) {
                        return _vm.selectAll($event)
                      },
                      sync: function($event) {
                        return _vm.search(true)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          (_vm.audiences.length || _vm.isLoading) && !_vm.isRouteChanged
            ? _c(
                "div",
                { attrs: { id: "grid-wrapper" } },
                [
                  _c("ag-grid-vue", {
                    staticClass: "audience-table ag-table ph-no-capture",
                    class: { disabled: _vm.isLoading },
                    attrs: {
                      gridOptions: _vm.gridOptions,
                      columnDefs: _vm.columnDefs,
                      defaultColDef: _vm.defaultColDef,
                      frameworkComponents: _vm.frameworkComponents,
                      rowData: _vm.audiences
                    },
                    on: {
                      sortChanged: _vm.sortChanged,
                      "row-clicked": function($event) {
                        return _vm.selectRow($event, "Show")
                      },
                      "grid-ready": _vm.onGridReady,
                      "grid-size-changed": _vm.onGridSizeChanged,
                      "selection-changed": _vm.onSelectionChanged
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade", appear: "" } }, [
            _vm.isLoading || _vm.isRouteChanged
              ? _c(
                  "div",
                  { key: "loading" },
                  _vm._l(20, function(elem) {
                    return _c("div", { staticClass: "audience-skeleton" }, [
                      _c("span", { staticClass: "skeleton-checkbox" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "skeleton-avatar" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "skeleton-lines" }, [
                        _c("i", { staticClass: "skeleton-title" }),
                        _vm._v(" "),
                        _c("i", { staticClass: "skeleton-subtitle" })
                      ])
                    ])
                  }),
                  0
                )
              : !_vm.isLoading && !_vm.isRouteChanged && !_vm.audiences.length
              ? _c(
                  "div",
                  {
                    key: "empty",
                    staticClass: "side-panel-group-empty txt-center"
                  },
                  [
                    _vm.hasFilter ||
                    (_vm.segment && _vm.segment.conditionsCount)
                      ? [
                          _c("illustration-dog-digging"),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "txt-heading txt-bold mt-4" },
                            [_vm._v("No results found.")]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "txt-body txt-muted" }, [
                            _vm._v(
                              "Try again with another search term or change filters."
                            )
                          ])
                        ]
                      : !_vm.hasFilter && !_vm.segment
                      ? [
                          _c("illustration-dog-bowl"),
                          _vm._v(" "),
                          _c(
                            "h4",
                            { staticClass: "txt-heading txt-bold mt-4 mb-2" },
                            [_vm._v("Your Audience list is empty.")]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "txt-body txt-muted" }, [
                            _vm._v(
                              "Your Audience contains everyone who has accessed your content in the past or may access it in the future. "
                            ),
                            _c("br"),
                            _vm._v(
                              " Add people to your audience one-by-one or import a list of people with a CSV file.\n          "
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "txt-primary btn-link-text",
                                attrs: {
                                  target: "_blank",
                                  href:
                                    "https://help.searchie.io/en/articles/5485696-the-audiences-tab"
                                }
                              },
                              [_vm._v("Learn more.")]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-btn",
                            {
                              attrs: { variant: "primary mt-4" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openPersonDialog($event)
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "btn-left",
                                  attrs: { viewBox: "0 0 24 24" }
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#icon-user-add" }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("b", [_vm._v("Add Person")])
                            ]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          !_vm.isLoading && !_vm.isRouteChanged && _vm.audiences.length
            ? _c("pagination", {
                attrs: {
                  "count-total": _vm.total,
                  "current-page": _vm.page,
                  pages: _vm.lastPage,
                  "per-page-size": 5
                },
                on: { "switch-page": _vm.currentPage }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("person-dialog", { on: { close: _vm.clearFocus } }),
          _vm._v(" "),
          _c("export-dialog"),
          _vm._v(" "),
          _c("segment-dialog"),
          _vm._v(" "),
          _vm.audience
            ? _c(
                "delete-dialog",
                {
                  attrs: {
                    id: "audience-delete-dialog",
                    title: "Delete Person",
                    processing: _vm.isDeleting
                  },
                  on: { accept: _vm.deleteAudience }
                },
                [
                  _c("div", { staticClass: "txt-heading-small" }, [
                    _vm._v("Are you sure you want to delete "),
                    _c("br"),
                    _c("b", [_vm._v(_vm._s(_vm.audience.name))]),
                    _vm._v("?")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("audience-segment-dialogs"),
          _vm._v(" "),
          _c("bulk-dialog"),
          _vm._v(" "),
          _c("exit-dialog", {
            attrs: {
              id: "exit-person-dialog",
              subtitle: _vm.exitDialogSubtitle,
              title: "Save changes before exit?",
              description: "Unsaved data will be lost.",
              "accept-label": "Save & Exit",
              "reject-label": "Exit"
            },
            on: {
              reject: _vm.rejectPersonSettingsExit,
              accept: _vm.acceptPersonSettingsExit
            }
          }),
          _vm._v(" "),
          _c("exit-dialog", {
            attrs: {
              id: "audience-bulk-dialog",
              title: "Would you like to apply changes before leaving?",
              subtitle: "",
              description: "Unapplied changes will be lost.",
              "accept-label": "Apply & Close",
              "reject-label": "Close"
            },
            on: {
              reject: _vm.rejectAudienceBulk,
              accept: _vm.acceptAudienceBulk
            },
            scopedSlots: _vm._u([
              {
                key: "subtitle",
                fn: function() {
                  return [
                    _c("b", { staticClass: "txt-primary" }, [
                      _vm._v("Apply Edits?")
                    ])
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm._v(" "),
          _vm.exportItem
            ? _c("delete-dialog", {
                attrs: {
                  id: "export-delete-dialog",
                  title: "Delete Export?",
                  "resource-name": _vm.exportItem.title,
                  processing: _vm.isDeleting
                },
                on: { accept: _vm.deleteExport }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedAudience
            ? _c(
                "delete-dialog",
                {
                  attrs: {
                    id: "bulk-delete-dialog",
                    title: "Remove people?",
                    processing: _vm.isDeleting
                  },
                  on: { accept: _vm.deleteBulkAudience }
                },
                [
                  _c("div", { staticClass: "txt-heading-small" }, [
                    _vm._v("Are you sure you want to remove "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.selectedAudience.length) +
                          " " +
                          _vm._s(
                            _vm.selectedAudience.length > 1
                              ? "people"
                              : "person"
                          )
                      )
                    ]),
                    _vm._v(" from your Audience?")
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.exportItem
            ? _c("rename-dialog", {
                attrs: {
                  id: "export-rename-dialog",
                  title: "Rename",
                  processing: _vm.renameProcessing,
                  placeholder: "Enter new title here"
                },
                on: { accept: _vm.renameExport },
                model: {
                  value: _vm.exportItem.title,
                  callback: function($$v) {
                    _vm.$set(_vm.exportItem, "title", $$v)
                  },
                  expression: "exportItem.title"
                }
              })
            : _vm._e()
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }