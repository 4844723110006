import {set} from "@app/utils/helpers";
import Renderer from "@plugins/Renderer/Renderer/Renderer";
import templateString from "@app/utils/templateString";

const isEmpty = (data) => {
  const renderer = new Renderer({noNodes: true});
  let json = verifyJSON('span', data);
  const string = templateString(renderer.render(json));
  return !string || string && !string.length
}

const isJson = (item) => {
  item = typeof item !== "string"
    ? JSON.stringify(item)
    : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  return typeof item === "object" && item !== null;
}

const verifyJSON = (el, value) => {
  if ( !el ) return value;
  let json = value;
  if ( isJson(value) ) {
    json = (typeof value === 'string') ? JSON.parse(value) : value;
    if ( !json.content || !json.content.length || json.content.length > 1 || el === 'div' ) return value;
    if ( el.includes('h') ) {
      json.content[0].type = 'heading';
      set(json, 'content.0.type', 'heading');
      set(json, 'content.0.attrs.level', parseInt(el[1]));
    }
  }
  return json;
}

export { isJson, isEmpty };

export default verifyJSON;
