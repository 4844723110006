<template>
  <audience-slide-in-template top-label="Export Audience" backRoute="">
    <side-panel-title
      ref="title"
      class="mb-2"
      v-model="title"
      :showActions="true"
      :error="$v.title.$error"
      :error-message="errors[0]"
      editable
      @change="$v.title.$touch()"
      @blur="onBlur"
    />
    <hr class="mt-0 mb-4">
    <div class="side-panel-group-header mb-4">
      <b-avatar-group class="mr-2" v-if="audienceList.length" size="32px" rounded>
        <b-avatar style="min-width: 32px" v-if="index < 6" v-for="(a, index) in audienceList" v-b-tooltip.hover :title="a.name"  :key="index" :src="a | avatar"></b-avatar>
      </b-avatar-group>
      <span class="txt-body mr-auto ml-1" v-if="count > 6"><b>+{{count - 6}}</b><span class="txt-muted"> {{ `${(count === 1) ? ' Person' : ' People'}` }}</span></span>
    </div>
    <!-- <b-avatar-group size="32px" rounded>
      <b-avatar v-b-tooltip="audience.name" style="min-width: 32px" v-for="audience in item.top_audiences.slice(0,5)" :key="audience.hash" :src="audience | avatar"></b-avatar>
    </b-avatar-group> -->
    <div class="side-panel-widget pt-2">
      <div class="audience-edit-row px-4 py-3">
        <h4 class="txt-heading-small txt-muted mb-0">Review fields you want to export.</h4>
      </div>
      <draggable
        ref="draggable"
        v-model="fields"
        v-bind="dragOptions"
        tag="div"
        handle=".handle"
        @start="isDragging=true"
      >
        <export-field
          :disabled="isSaving"
          v-for="(field, index) in fields"
          :key="index"
          :field="field"
          :list="fields"
          :field-options="fieldOptions"
          :state="$v.fields.$each[index]"
          @delete="deleteField(index)"/>
      </draggable>
      <hr class="my-0" />
      <b-btn block variant="segment" @click="addField" :disabled="!canAdd">
        <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-plus-circle"></use></svg>
        Add Field
      </b-btn>
    </div>
    <div class="side-panel-widget">
        <side-panel-option
          :link="{name: 'RecentExports'}"
          label="Recent Exports"
          description="View Audience you exported earlier."
          icon-right="arrow-right"
        />
    </div>
    <div class="side-panel-library-cta p-3">
      <b-btn block variant="primary" @click.stop="confirm" :disabled="isSaving">
        <svg class="btn-left" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-status-success"></use></svg>
        Export
      </b-btn>
    </div>
  </audience-slide-in-template>
</template>

<script>
  import { debounce, get } from 'lodash';
  import {required, minLength} from 'vuelidate/lib/validators';
  import axios from '@app2/api/axios';
  import Segment from "@app2/models/Segment";
  import { mapGetters } from 'vuex'
  import draggable from 'vuedraggable';
  import AudienceSlideInTemplate from "@app2/modules/Audience/Components/Template";
  import SidePanelTitle from '@app2/core/Components/Sidepanel/Title.vue';
  import ExportField from '@app2/modules/Audience/Export/Components/Field'
  import SidePanelOption from "@app2/core/Components/Sidepanel/Option";

  import rootVue from "@app2/app"
  import exportRouter from '@app2/modules/Audience/Export/export.router'

  export default {
    name: 'AudienceExport',
    components: {
      AudienceSlideInTemplate,
      draggable,
      SidePanelTitle,
      ExportField,
      SidePanelOption
    },
    data() {
      return {
        isSaving: false,
        hasChanged: false,
        title: '',
        initial: null,
        fields: [
          {title: 'email', label: 'Email'},
          {title: 'name', label: 'Full Name'},
          {title: 'first_name', label: 'First Name'},
          {title: 'last_name', label: 'Last Name'},
          {title: 'created_at', label: 'Registration Date'},
          {title: 'phone', label: 'Phone'},
        ],
        fieldOptions: [
          {title: 'email', label: 'Email'},
          {title: 'name', label: 'Full Name'},
          {title: 'first_name', label: 'First Name'},
          {title: 'last_name', label: 'Last Name'},
          {title: 'created_at', label: 'Registration Date'},
          {title: 'phone', label: 'Phone'},
        ],
        isDragging: false,
        dragOptions: {
          animation: 150,
          group: "description",
          ghostClass: "ghost",
          handle: '.icon-draggable'
        },
      }
    },
    validations: {
      title: {
        required,
        minLen: minLength(1),
      },
      fields: {
        required,
        minLength: minLength(1),
        $each: {
          title: {
            required,
            minLength: minLength(1)
          },
          label: {
            required,
            minLength: minLength(1)
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        total: 'v2/audience/total',
        preview: 'v2/audience/segment/getPreview',
        segment: 'v2/audience/segment/getSegment',
        audiences: 'v2/audience/getAudiences',
        selectedAudience: 'v2/audience/selectedAudience'
      }),
      count() {
        if(this.selectedAudience.length) {
          return this.selectedAudience.length
        } else {
          return this.total
        }
      },
      audienceList() {
        return this.selectedAudience.length ? this.selectedAudience : this.audiences
      },
      errors() {
        let errors = [];
        if ( this.$v.title.$error ) errors.push('Export name is required.');
        if ( this.$v.fields.$error && this.fields.length ) errors.push('Field title and label are required.');
        if ( this.$v.fields.$error && !this.fields.length ) errors.push('You must add at least one field.');
        return errors;
      },
      canAdd() {
        if ( this.fields.length === this.fieldOptions.length ) return false;
        return this.fieldOptions.filter(op => !this.fields.map(f => f.title).includes(op.title)).length
      }
    },
    watch: {
      title() {
        this.hasChanged = true;
      },
      fields: {
        handler(val, oldVal) {
          this.hasChanged = true;
        },
        deep: true
      }
    },
    methods: {
      onBlur: debounce(async function() {

      }, 1000),
      addField() {
        this.fields.push({
          title: null,
          label: ''
        })
      },
      deleteField(index) {
        this.fields.splice(index, 1);
      },
      async confirm() {
        this.$v.$touch();
        if (this.$v.$error) return;
        this.isSaving = true;
        let exportArgs = {
          title: this.title,
          fields: this.fields,
        };
        if ( this.selectedAudience.length) {
          exportArgs.source = 'audiences';
          exportArgs.source_data = this.selectedAudience.map(a => a.id);
        } else {
          if ( this.preview || this.segment ) {
            exportArgs.source = 'segments';
            let segment = Segment.find(this.preview ? this.preview : this.segment);
            if (segment.id !== 'create-00') {
              exportArgs.source_data = [segment.id];
            } else {
              exportArgs.source = 'all_audiences';
            }
          } else {
            exportArgs.source = 'all_audiences';
          }
        }
        await axios.post(`/api/audience/exports`, exportArgs);
        this.isSaving = false;
        this.$store.commit('v2/audience/exports/SET_MODAL_STATE', false)

        this.$toasted.show('<p class="txt-body mb-1">Exporting in progress...</p>', {
          duration: 1500,
          type: 'info',
          action: {
            text: 'See all exports',
            onClick: (e, toastObject) => {
              exportRouter.push({name: 'RecentExports'}).catch(() => {})
              rootVue.$bvModal.show('export-dialog');
            },
          },
        });
      },
      reset() {
        this.isSaving = false;
        this.hasChanged = false;
        this.fields = [
          {title: 'email', label: 'Email'},
          {title: 'name', label: 'Full Name'},
          {title: 'first_name', label: 'First Name'},
          {title: 'last_name', label: 'Last Name'},
          {title: 'created_at', label: 'Registration Date'},
          {title: 'phone', label: 'Phone'},
        ];
        this.title = '';
        this.$v.$reset();
      },
    }
  }
</script>
