<template>
  <div class="side-panel-template">
    <div class="side-panel-header pt-4">
      <div class="inner mb-2">
        <router-link v-if="backRoute" :to="backRoute" custom v-slot="{ navigate }">
          <a @click="navigate" role="link" class="txt-primary">
            <svg class="icon-regular" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-left"></use></svg>
            <span class="back-label">{{ backLabel }}</span>
          </a>
        </router-link>
        <div v-if="topLabel" class="top-label txt-body txt-muted my-1 mr-auto">{{topLabel}}</div>
        <side-panel-title
          v-if="!backRoute && !hidePanelTitle"
          class="mb-auto"
          ref="title"
          :error="titleError"
          :error-message="titleErrorMessage"
          @blur="$emit('blurTitle')"
          :show-actions="renameActions"
          :key="$route.fullPath + titleKey"
          :editable="rename"
          v-model="titleModel" />
        <slot name="header-cta"></slot>
        <b-btn variant="icon ml-auto" size="sm" @click="closePanel">
          <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
        </b-btn>
      </div>
      <div class="side-panel-group-header-sm pt-0 my-0" v-if="backRoute && !hidePanelTitle">
        <side-panel-title
          class="my-0"
          ref="title"
          :error="titleError"
          :error-message="titleErrorMessage"
          @blur="$emit('blurTitle')"
          :show-actions="renameActions"
          :key="$route.fullPath + titleKey"
          :editable="rename"
          v-model="titleModel"
        />
          <template v-if="hasChanged">
            <b-btn variant="secondary" :disabled="isBusy" @click="$emit('cancel')">Cancel</b-btn>
            <b-btn variant="primary px-4 ml-2" :disabled="isBusy" @click="$emit('save')">Save</b-btn>
          </template>
      </div>
      <h5 v-if="subtitle" class="side-panel-title txt-bold">{{ subtitle }}</h5>
    </div>
    <div class="side-panel-body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
  import SidePanelTitle from "@app2/core/Components/Sidepanel/Title";
  import get from "lodash/get";
  import rootVue from "@app2/app";

  export default {
    name: 'AudienceSlideInTemplate',
    components: {SidePanelTitle},
    computed: {
      titleModel: {
        get() { return this.title },
        set(val) { this.$emit('rename', val); },
      },
    },
    props: {
      hidePanelTitle: {
        type: Boolean,
        default: false
      },
      subtitle: String,
      topLabel: {
        type: String,
        default: ''
      },
      deleteAction: Boolean,
      deleteLabel: String,
      backRoute: {},
      backLabel: {},
      title: {},
      titleError: Boolean,
      hasChanged: Boolean,
      isBusy: Boolean,
      titleKey: {},
      titleErrorMessage: String,
      rename: Boolean,
      renameActions: Boolean,
    },
    methods: {
      closePanel() {
        switch(get(this.$router, 'options.name')) {
          case 'audience-segment':
            rootVue.$bvModal.hide('segment-editor-dialog');
            break
          case 'audience-person':
            rootVue.$bvModal.hide('person-editor-dialog');
            break
          case 'audience-export':
            rootVue.$bvModal.hide('export-dialog');
            break
          case 'audience-import':
            rootVue.$bvModal.hide('import-dialog');
            break
          case 'audience-bulk':
            rootVue.$bvModal.hide('bulk-dialog');
            break
        }
      },
      focus() {
        if ( this.$refs.title ) this.$refs.title.focusTitle();
      }
    },
  }
</script>
