import Vue from 'vue';
import Router from 'vue-router';
import store from "@app2/store";
import app from '@app2/app'
import appRouter from '@app2/router'
import eventBus from "@app2/core/eventBus";
import Hub from "@app2/models/Hub";

const Wisdom = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Wisdom/Index')
const Appearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Appearance')
const Colors = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Colors')
const Typography = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Typography')
const Logos = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Logos')
const Theme = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Theme')
const Global = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Global')
const Buttons = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Buttons')
const Images = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Images')
const Labels = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Appearance/Labels')
const Content = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Content')
const Attributes = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Attributes')
const Pages = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Pages')
const Page = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Page')
const PageSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/PageSettings')
const PageSectionCreate = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Create')
const PageSection = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Section')
const PageSectionSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings')
const PageSectionAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Appearance')
const PageSectionType = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/SelectType')
const PageBlock = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Blocks/Block')
const PageBlockType = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Blocks/Type')
const PageBlockSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Blocks/Settings')
const PageBlockAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Blocks/Appearance')
const PageElementCreate = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Elements/Create')
const PageElement = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Elements/Element')
const PageElementType = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Elements/Type')
const PageElementSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Elements/Settings')
const PageElementAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Elements/Appearance')
const Header = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Common/Header')
const Footer = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Common/Footer')
const ThemePage = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/ThemePage')
const ThemePageSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Default/Settings')
const ThemePagePartial = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Default/Partials/Partial')
const ThemePagePartialSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Default/Partials/Settings')
const ThemePagePartialAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Default/Partials/Appearance')
const Menu = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Common/Menu')
const MenuItem = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/MenuItems/MenuItem')
const MenuItemType = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/MenuItems/Type')
const MenuItemCreate = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/MenuItems/Create')
const HeaderAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Common/Appearance/Header')
const HeaderSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Common/Settings/Header')

// section elements
const PageSectionElement = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Element')
const ButtonSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/ButtonSettings')
const ButtonAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/ButtonAppearance')

const HeadlineSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/HeadlineSettings')
const HeadlineAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/HeadlineAppearance')

const DescriptionSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/DescriptionSettings')
const DescriptionAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/DescriptionAppearance')

const ThumbnailSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/ThumbnailSettings')
const ThumbnailAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/ThumbnailAppearance')

const IconSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/IconSettings')
const IconAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/IconAppearance')

const MenuItemSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/MenuItems/Settings/Index')
const MenuItemAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/MenuItems/Appearance/Index')

const SearchSettings = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Settings/SearchSettings')
const SearchAppearance = () => import(/* webpackChunkName: "v2-hub-editor" */ '@app2/modules/Hubs/Editor/Pages/Sidebar/Sections/Settings/Elements/Pages/Appearance/SearchAppearance')

Vue.use(Router);

const elementRoutes = () => {
  let routes = [],
      prefix = '/pages/:hash/sections/:section/';
  [
    {
      path: 'blocks/:block/',
      name: 'PageElement'
    },
    {
      path: '',
      name: 'PageSectionElement'
    }].forEach(op => {
    routes = routes.concat([
      {
        path: `${prefix}${op.path}elements/create`,
        name: `${op.name}Create`,
        component: PageElementCreate,
      },
      {
        path: `${prefix}${op}elements/:element`,
        name: op.name,
        component: PageElement,
        redirect: { name: `${op.name}Settings`},
        children: [
          {
            path: '',
            name: `${op.name}Settings`,
            component: PageElementSettings,
          },
          {
            path: `${prefix}${op}elements/:element/appearance`,
            name: `${op.name}Appearance`,
            component: PageElementAppearance,
          },
        ]
      },
      {
        path: `${prefix}${op}elements/:element/type`,
        name: `${op.name}Type`,
        component: PageElementType,
      },
    ])
  })
  return routes;
}

const router = new Router({
  mode: 'abstract',
  routes: [
    {
      path: '/',
      name: 'Pages',
      component: Pages,
    },
    {
      path: '/pages/header',
      name: 'Header',
      component: Header,
      redirect: { name: 'HeaderSettings'},
      children: [
        {
          path: '',
          name: 'HeaderSettings',
          component: HeaderSettings,
        },
      ]
    },
    {
      path: '/pages/footer',
      name: 'Footer',
      component: Footer,
    },
    {
      path: '/pages/:hash',
      name: 'Page',
      component: Page,
    },
    {
      path: '/pages/:hash/settings',
      name: 'PageSettings',
      component: PageSettings,
    },
    {
      path: '/pages/:hash/header',
      name: 'PageHeader',
      component: Header,
      redirect: { name: 'PageHeaderSettings'},
      children: [
        {
          path: '',
          name: 'PageHeaderSettings',
          component: HeaderSettings,
        },
        {
          path: '/pages/:hash/header',
          name: 'HeaderAppearance',
          component: HeaderAppearance,
        },
      ]
    },
    {
      path: '/pages/:hash/footer',
      name: 'PageFooter',
      component: Footer,
    },
    {
      path: '/pages/:hash/sections/create',
      name: 'PageSectionCreate',
      component: PageSectionCreate,
    },
    {
      path: '/pages/:hash/sections/create/:layout',
      name: 'PageSectionCreateLayout',
      component: PageSectionCreate,
    },
    {
      path: '/pages/:hash/sections/:section',
      name: 'PageSection',
      component: PageSection,
      redirect: { name: 'PageSectionSettings'},
      children: [
        {
          path: '',
          name: 'PageSectionSettings',
          component: PageSectionSettings,
        },
        {
          path: '/pages/:hash/sections/:section/appearance',
          name: 'PageSectionAppearance',
          component: PageSectionAppearance,
        },
      ]
    },
    // {
    //   path: '/pages/:hash/sections/:section/elements',
    //   name: 'PageSectionElements',
    //   component: PageSectionElements,
    //   children: [
    //     {
    //       path: '',
    //       name: 'PageSectionElementButton',
    //       component: PageSectionElementButton,
    //     },
    //   ]
    // },
    {
      path: '/pages/:hash/sections/:section/partial-elements/button/:block?',
      name: 'PageSectionElementButton',
      component: PageSectionElement,
      redirect: { name: 'ButtonSettings'},
      children: [
        {
          path: 'settings',
          name: 'ButtonSettings',
          component: ButtonSettings,
          meta: { title: 'Button' },
        },
        {
          path: 'appearance',
          name: 'ButtonAppearance',
          component: ButtonAppearance,
          meta: { title: 'Button' }
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/partial-elements/headline/:block?',
      name: 'PageSectionElementHeadline',
      component: PageSectionElement,
      redirect: { name: 'HeadlineSettings'},
      children: [
        {
          path: 'settings',
          name: 'HeadlineSettings',
          component: HeadlineSettings,
          meta: { title: 'Headline' }
        },
        {
          path: 'appearance',
          name: 'HeadlineAppearance',
          component: HeadlineAppearance,
          meta: { title: 'Headline' }
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/partial-elements/description/:block?',
      name: 'PageSectionElementDescription',
      component: PageSectionElement,
      redirect: { name: 'DescriptionSettings'},
      children: [
        {
          path: 'settings',
          name: 'DescriptionSettings',
          component: DescriptionSettings,
          meta: { title: 'Description' }
        },
        {
          path: 'appearance',
          name: 'DescriptionAppearance',
          component: DescriptionAppearance,
          meta: { title: 'Description' }
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/partial-elements/thumbnail/:block?',
      name: 'PageSectionElementThumbnail',
      component: PageSectionElement,
      redirect: { name: 'ThumbnailSettings'},
      children: [
        {
          path: 'settings',
          name: 'ThumbnailSettings',
          component: ThumbnailSettings,
          meta: { title: 'Thumbnail' }
        },
        {
          path: 'appearance',
          name: 'ThumbnailAppearance',
          component: ThumbnailAppearance,
          meta: { title: 'Thumbnail' }
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/partial-elements/search',
      name: 'PageSectionElementSearch',
      component: PageSectionElement,
      redirect: { name: 'SearchSettings'},
      children: [
        {
          path: 'settings',
          name: 'SearchSettings',
          component: SearchSettings,
          meta: { title: 'Search' }
        },
        {
          path: 'appearance',
          name: 'SearchAppearance',
          component: SearchAppearance,
          meta: { title: 'Search' }
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/blocks/:block/icon',
      name: 'PageSectionElementIcon',
      component: PageSectionElement,
      redirect: { name: 'IconSettings'},
      children: [
        {
          path: '/pages/:hash/sections/:section/blocks/:block/icon/settings',
          name: 'IconSettings',
          component: IconSettings,
          meta: { title: 'Icon' }
        },
        {
          path: '/pages/:hash/sections/:section/blocks/:block/icon/appearance',
          name: 'IconAppearance',
          component: IconAppearance,
          meta: { title: 'Icon' }
        }
      ]
    },
    {
      path: '/pages/:hash/sectionType',
      name: 'PageSectionSetType',
      component: PageSectionType,
    },
    {
      path: '/pages/:hash/sections/:section/type',
      name: 'PageSectionType',
      component: PageSectionType,
    },
    {
      path: '/pages/:hash/sections/:section/blocks/:block',
      name: 'PageBlock',
      component: PageBlock,
      redirect: { name: 'PageBlockSettings'},
      children: [
        {
          path: '',
          name: 'PageBlockSettings',
          component: PageBlockSettings,
        },
        {
          path: '/pages/:hash/sections/:section/blocks/:block/appearance',
          name: 'PageBlockAppearance',
          component: PageBlockAppearance,
        },
      ]
    },
    {
      path: '/pages/:hash/sections/:section/blockType',
      name: 'PageBlockSetType',
      component: PageBlockType,
    },
    {
      path: '/pages/:hash/sections/:section/blocks/:block/type',
      name: 'PageBlockType',
      component: PageBlockType,
    },
    ...elementRoutes(),
    {
      path: '/pages/theme/:id',
      name: 'ThemePage',
      component: ThemePage,
    },
    {
      path: '/pages/theme/:id/header',
      name: 'ThemePageHeader',
      component: Header,
      redirect: { name: 'ThemePageHeaderSettings'},
      children: [
        {
          path: '',
          name: 'ThemePageHeaderSettings',
          component: HeaderSettings,
        },
      ]
    },
    {
      path: '/pages/theme/:id/footer',
      name: 'ThemePageFooter',
      component: Footer,
    },
    {
      path: '/pages/theme/:id/settings/:settings?',
      name: 'ThemePageSettings',
      component: ThemePageSettings,
    },
    {
      path: '/pages/theme/:id/partials/:partial',
      name: 'ThemePagePartial',
      component: ThemePagePartial,
      children: [
        {
          path: 'settings',
          name: 'ThemePagePartialSettings',
          component: ThemePagePartialSettings,
        },
        {
          path: 'appearance',
          name: 'ThemePagePartialAppearance',
          component: ThemePagePartialAppearance,
        },
      ]
    },
    {
      path: '/menus/:menu',
      name: 'Menu',
      component: Menu,
    },
    {
      path: '/menus/:menu/create',
      name: 'MenuItemCreate',
      component: MenuItemCreate,
    },
    {
      path: '/menus/:menu/:menuItem',
      name: 'MenuItem',
      component: MenuItem,
      redirect: { name: 'MenuItemSettings' },
      children: [
        {
          path: '/menus/:menu/:menuItem/settings',
          name: 'MenuItemSettings',
          component: MenuItemSettings
        },
        {
          path: '/menus/:menu/:menuItem/appearance',
          name: 'MenuItemAppearance',
          component: MenuItemAppearance
        },
      ]
    },
    {
      path: '/menus/:menu/:menuItem/type',
      name: 'MenuItemType',
      component: MenuItemType,
    },
    {
      path: '/wisdom',
      name: 'Wisdom',
      component: Wisdom,
      meta: {
        tab: 'Wisdom'
      }
    },
    {
      path: '/appearance',
      name: 'Appearance',
      component: Appearance,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/colors',
      name: 'Colors',
      component: Colors,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/typography',
      name: 'Typography',
      component: Typography,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/logos',
      name: 'Logos',
      component: Logos,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/theme',
      name: 'Theme',
      component: Theme,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/global',
      name: 'Global',
      component: Global,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/buttons',
      name: 'Buttons',
      component: Buttons,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/labels',
      name: 'Labels',
      component: Labels,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/appearance/images',
      name: 'Images',
      component: Images,
      meta: {
        tab: 'Appearance'
      }
    },
    {
      path: '/content',
      name: 'Content',
      component: Content,
    },
    {
      path: '/attributes',
      name: 'Attributes',
      component: Attributes,
    },
  ],
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if ( to?.params?.section && to?.params?.section !== from?.params?.section ) {
    eventBus.$emit('hubEditorSetSection', to.params.section);
  }
  // hide wisdom editor
  if ( from.meta && from.meta.tab === 'Wisdom' ) {
    app.$bvModal.hide('widget-editor');
  }
  // onboarding exception
  let hub = Hub.find(appRouter.currentRoute.params.hash);
  let page = store.getters['v2/hubs/editor/page'](hub.id, hub.hash);
  let isDefault = ( page && ['onboarding', 'login', 'register'].includes(page.type) && to.params.id === page.type );
  if ( page && page.type === 'onboarding' && from.name === 'PageSection' && to.name === 'Page' ) {
    store.commit('v2/hubs/editor/SET_ONBOARDING_STEP', 'profile');
    eventBus.$emit('sendData', { defaultOnboardingStep: 'profile' });
  }
  if ( !isDefault && from.path.includes('page') && to.path.includes('page') && from.params.hash && from.params.hash !== to.params.hash && store.getters['v2/hubs/editor/pageActions'] ) {
    store.commit('v2/hubs/editor/SET_EXIT_ROUTE', to)
    app.$bvModal.show('switch-page-dialog');
    next(false);
    return;
  }
  if ( to.path.includes('page') || to.name.includes('Page') ) {
    store.commit('v2/hubs/editor/SET_SIDENAV_TAB', 'Pages')
  }
  if ( to.meta && to.meta.tab === 'Appearance' ) {
    store.commit('v2/hubs/editor/SET_SIDENAV_TAB', 'Appearance')
  }
  if ( to.meta && to.meta.tab === 'Wisdom' ) {
    store.commit('v2/hubs/editor/SET_SIDENAV_TAB', 'Wisdom')
  }
  if ( to.path.includes('content') ) {
    store.commit('v2/hubs/editor/SET_SIDENAV_TAB', 'Content')
  }
  if ( to.path.includes('attributes') ) {
    store.commit('v2/hubs/editor/SET_SIDENAV_TAB', 'Attributes')
  }
  next();
})

export default router;
