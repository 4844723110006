<template>
  <div class="audience-header" :class="{'no-bg': noAudience}">
    <div class="audience-header-inner">
      <h1 class="txt-title txt-bold mb-0">Audience</h1>
      <div class="slot-right" v-if="!noAudience">
        <div id="save-as-segment">
          <b-btn class="mx-3" variant="icon" v-if="isTemporary && showFilters" @click="saveSegment" :disabled="!$can('segmenting')"><svg class="mr-2" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-user-multiple-add"></use></svg>Save as segment</b-btn>
        </div>
        <b-tooltip
          v-if="!$can('segmenting')"
          target="save-as-segment"
          placement="top"
          triggers="hover"
        >
          <button class="tooltip-cta txt-white" @click.stop="redirect">
            <span class="tooltip-cta-inner">Upgrade your plan to use this feature.</span>
            <span class="btn-icon ml-2 p-0">
              <svg class="icon-regular" viewBox="0 0 24 24"><use xlink:href="#icon-arrow-right"></use></svg>
            </span>
          </button>
        </b-tooltip>
        <b-btn v-b-tooltip.hover.bottom="hoverText" variant="filter px-2" :disabled="loading && false" @click="openSegmentDialog">
          <template v-if="showFilters">
            <template v-if="isTemporary">
              <span class="count mr-2">{{ segment.conditionsCount }}</span>
              {{ segment.conditionsCount === 1 ? 'Filter' : 'Filters'  }}
            </template>
            <template v-else>
              <svg class="btn-left ml-1" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-filter"></use></svg>
              <b class="ml-1">{{ segment.title }}</b>
              <b-avatar-group v-if="segment.audiences.length" class="ml-2" size="32px" rounded>
                <b-avatar style="min-width: 32px" v-for="(a, index) in segment.audiences" v-b-tooltip.hover :title="a.name"  :key="index" :src="a | avatar"></b-avatar>
              </b-avatar-group>
            </template>
            <b-btn variant="icon primary" size="sm" @click.stop.prevent="$emit('reset')" class="ml-2">
              <svg class="icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-cross"></use></svg>
            </b-btn>
          </template>
          <svg v-else width="24" viewBox="0 0 24 24"><use xlink:href="#icon-filter"></use></svg>
        </b-btn>
        <hr class="vertical" />
        <b-btn id="add-audience" variant="primary" v-b-tooltip.hover title="Add Person" @click.stop="">
          <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-user-add"></use></svg>
        </b-btn>
        <b-popover ref="dd" placement="leftbottom" target="add-audience" triggers="click blur">
          <div class="dropdown-cont sm">
            <b-dropdown-item  @click.stop="openPersonDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-user-single"></use></svg>
              Add Person
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click.stop="openImportDialog">
              <svg class="btn-left icon-sm" width="24" viewBox="0 0 24 24"><use xlink:href="#icon-user-multiple"></use></svg>
              Import Audience
            </b-dropdown-item>
          </div>
        </b-popover>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
  import rootVue from "@app2/app";
  import Segment from "@app2/models/Segment";
  import personRouter from "@app2/modules/Audience/Person/person.router"
  import importRouter from "@app2/modules/Audience/Import/import.router"
  import router from "@app2/modules/Audience/Segment/segment.router";
  import rootRouter from "@app2/router";
  import eventBus from "@app2/core/eventBus";

  export default {
    name: 'AudienceHeader',
    props: {
      noAudience: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        audiences: [],
      }
    },
    mounted () {
      const { add_person } = this.$route.query
      if (add_person) this.$nextTick(() => this.openPersonDialog())
    },
    computed: {
      showSegment() {
        return !this.$store.state.v2.audience.segment.isModalOpen && this.segment;
      },
      showFilters() {
        return this.$store.state.v2.audience.segment.showFilters;
      },
      showSaveSegment() {
        return this.$store.state.v2.audience.segment.showFilters;
      },
      isTemporary() {
        return this.segment && this.segment.id === 'create-00';
      },
      segment() {
        return Segment.find(this.$store.state.v2.audience.segment.currentSegment);
      }, 
      hoverText() {
        if (this.showFilters && !this.isTemporary) {
          return ""
        } else {
          return "Filter and Segment"
        }
      }
    },
    methods: {
      openSegmentDialog() {
        // router.push({name: 'Create'}).catch(() => {});
        this.$nextTick(() => {
          rootVue.$bvModal.show('segment-editor-dialog');
        })
      },
      openPersonDialog() {
        personRouter.push({name: 'Create'}).catch(() => {})
        rootVue.$bvModal.show('person-editor-dialog');
      },
      openImportDialog() {
        importRouter.push({name: 'ImportIndex'}).catch(() => {})
        rootVue.$bvModal.show('import-dialog');
      },
      async saveSegment() {
        if ( this.segment.isInvalid ) {
          this.validate();
        } else {
          eventBus.$emit('segmentToCreate', this.segment);
        }
      },
      redirect() {
        rootRouter.push({name: 'AccountSubscription'});
      }
    }
  }
</script>
