const Domain = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Domain')
const Emails = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Emails')
const Payments = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Payments')
const Privacy = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Privacy')
const SEO = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/SEO')
const Tracking = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Tracking')
const Pages = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Pages')
const Achievements = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Achievements')
const Notifications = () => import(/* webpackChunkName: "v2-hub-settings" */ '@app2/modules/Hubs/Settings/Pages/Routes/Notifications')

export const beforeEditorLeave = (to, from, next, root) => {
  if ( root.hub.hasChanged ) {
    root.$store.commit('v2/hubs/settings/SET_EXIT_ROUTE', to)
    root.$bvModal.show('exit-settings-dialog');
    next(false);
    return;
  }
  next();
}

export default [
  {
    path: 'domain',
    name: 'hubSettingsDomain',
    component: Domain,
  },
  {
    path: 'emails',
    name: 'hubSettingsEmails',
    component: Emails,
  },
  {
    path: 'payments',
    name: 'hubSettingsPayments',
    component: Payments,
  },
  {
    path: '',
    name: 'hubSettingsPrivacy',
    component: Privacy,
  },
  {
    path: 'seo',
    name: 'hubSettingsSEO',
    component: SEO,
  },
  {
    path: 'tracking',
    name: 'hubSettingsTracking',
    component: Tracking,
  },
  {
    path: 'pages',
    name: 'hubSettingsPages',
    component: Pages,
  },
  {
    path: 'achievements',
    name: 'hubSettingsAchievements',
    component: Achievements,
  },
  {
    path: 'notifications',
    name: 'hubSettingsNotifications',
    component: Notifications,
  },
];
