<template>
  <div class="user-avatar mt-4 mb-3" :class="{ [`size-${size}`]: true }">
    <img v-if="model && !error" :src="model" @error="error = true">
    <b-avatar v-else :variant="variant" :src="model" @img-error="error = true" rounded>
      <svg v-if="false" class="m-auto" width="148" height="148" viewBox="0 0 148 148" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="74" cy="74" r="70" fill="#D9D9D9"/>
        <path vector-effect="non-scaling-stroke" d="M74.3715 77.7391C88.2943 77.7391 99.5809 66.4525 99.5809 52.5297C99.5809 38.6069 88.2943 27.3203 74.3715 27.3203C60.4487 27.3203 49.1621 38.6069 49.1621 52.5297C49.1621 66.4525 60.4487 77.7391 74.3715 77.7391Z" stroke="#707070" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path vector-effect="non-scaling-stroke" d="M117.965 128.648L112.545 109.431C111.105 102.949 105.342 98.627 98.86 98.627H48.4412C41.9588 98.627 36.1966 102.949 34.7561 109.431L29.3359 128.648" stroke="#707070" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <svg class="m-auto w-100" width="148" height="149" viewBox="0 0 148 149" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_userSettings)">
          <circle cx="74" cy="74.8281" r="70" fill="#D9D9D9"/>
          <path d="M74.3715 78.5672C88.2943 78.5672 99.5809 67.2806 99.5809 53.3578C99.5809 39.4351 88.2943 28.1484 74.3715 28.1484C60.4487 28.1484 49.1621 39.4351 49.1621 53.3578C49.1621 67.2806 60.4487 78.5672 74.3715 78.5672Z" stroke="#707070" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M117.965 129.474L112.545 110.257C111.105 103.775 105.342 99.4531 98.86 99.4531H48.4412C41.9588 99.4531 36.1966 103.775 34.7561 110.257L29.3359 129.474" stroke="#707070" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <rect x="3.5" y="4.32812" width="141" height="141" rx="70.5" stroke="black" stroke-opacity="0.05"/>
        <defs>
          <clipPath id="clip0_userSettings">
          <rect x="4" y="4.82812" width="140" height="140" rx="70" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </b-avatar>
    <div class="user-avatar-edit" v-if="editable">
      <input type="file" :multiple="false" ref="file" @input="update">
      <svg width="24" viewBox="0 0 24 24"><use xlink:href="#icon-pencil"></use></svg>
    </div>
  </div>
</template>
<script>
  import axios from "@app2/api/axios";

  export default {
    name: 'UserAvatar',
    data() {
      return {
        error: false,
      }
    },
    props: {
      value: {},
      size: {
        type: String,
        default: 'md'
      },
      variant: {
        type: String,
        default: 'primary'
      },
      editable: Boolean,
      url: {
        type: String,
        default: '/settings/photo'
      },
      name: {
        type: String,
        default: 'photo'
      }
    },
    computed: {
      model: {
        get() { return (this.value && !this.error) ? this.value : '' },
        set(val) { this.$emit('input') }
      }
    },
    methods: {
      update(e) {
        e.preventDefault();
        if ( ! this.$refs.file.files.length) {
          return;
        }
        let self = this;
        axios.post(this.url, this.gatherFormData())
          .then(
            async () => {
              this.$emit('upload')
            },
            (error) => {
              self.form.setErrors(error.response.data.errors);
            }
          );
      },
      gatherFormData() {
        const data = new FormData();
        data.append(this.name, this.$refs.file.files[0]);
        return data;
      }
    }
  }
</script>
