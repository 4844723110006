var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "audience-slide-in-template",
    { attrs: { "top-label": "Export Audience", backRoute: "" } },
    [
      _c("side-panel-title", {
        ref: "title",
        staticClass: "mb-2",
        attrs: {
          showActions: true,
          error: _vm.$v.title.$error,
          "error-message": _vm.errors[0],
          editable: ""
        },
        on: {
          change: function($event) {
            return _vm.$v.title.$touch()
          },
          blur: _vm.onBlur
        },
        model: {
          value: _vm.title,
          callback: function($$v) {
            _vm.title = $$v
          },
          expression: "title"
        }
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "mt-0 mb-4" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-group-header mb-4" },
        [
          _vm.audienceList.length
            ? _c(
                "b-avatar-group",
                { staticClass: "mr-2", attrs: { size: "32px", rounded: "" } },
                _vm._l(_vm.audienceList, function(a, index) {
                  return index < 6
                    ? _c("b-avatar", {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            modifiers: { hover: true }
                          }
                        ],
                        key: index,
                        staticStyle: { "min-width": "32px" },
                        attrs: { title: a.name, src: _vm._f("avatar")(a) }
                      })
                    : _vm._e()
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.count > 6
            ? _c("span", { staticClass: "txt-body mr-auto ml-1" }, [
                _c("b", [_vm._v("+" + _vm._s(_vm.count - 6))]),
                _c("span", { staticClass: "txt-muted" }, [
                  _vm._v(
                    " " + _vm._s("" + (_vm.count === 1 ? " Person" : " People"))
                  )
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget pt-2" },
        [
          _c("div", { staticClass: "audience-edit-row px-4 py-3" }, [
            _c("h4", { staticClass: "txt-heading-small txt-muted mb-0" }, [
              _vm._v("Review fields you want to export.")
            ])
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            _vm._b(
              {
                ref: "draggable",
                attrs: { tag: "div", handle: ".handle" },
                on: {
                  start: function($event) {
                    _vm.isDragging = true
                  }
                },
                model: {
                  value: _vm.fields,
                  callback: function($$v) {
                    _vm.fields = $$v
                  },
                  expression: "fields"
                }
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            _vm._l(_vm.fields, function(field, index) {
              return _c("export-field", {
                key: index,
                attrs: {
                  disabled: _vm.isSaving,
                  field: field,
                  list: _vm.fields,
                  "field-options": _vm.fieldOptions,
                  state: _vm.$v.fields.$each[index]
                },
                on: {
                  delete: function($event) {
                    return _vm.deleteField(index)
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "my-0" }),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              attrs: { block: "", variant: "segment", disabled: !_vm.canAdd },
              on: { click: _vm.addField }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left icon-sm",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-plus-circle" } })]
              ),
              _vm._v("\n      Add Field\n    ")
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-widget" },
        [
          _c("side-panel-option", {
            attrs: {
              link: { name: "RecentExports" },
              label: "Recent Exports",
              description: "View Audience you exported earlier.",
              "icon-right": "arrow-right"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "side-panel-library-cta p-3" },
        [
          _c(
            "b-btn",
            {
              attrs: { block: "", variant: "primary", disabled: _vm.isSaving },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm($event)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass: "btn-left",
                  attrs: { width: "24", viewBox: "0 0 24 24" }
                },
                [_c("use", { attrs: { "xlink:href": "#icon-status-success" } })]
              ),
              _vm._v("\n      Export\n    ")
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }