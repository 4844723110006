var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        id: _vm.id,
        static: _vm.static,
        centered: _vm.centered,
        size: "sm",
        "header-class": "pb-3"
      },
      on: {
        hidden: function($event) {
          return _vm.$emit("hidden")
        }
      },
      scopedSlots: _vm._u(
        [
          {
            key: "modal-header",
            fn: function(ref) {
              var close = ref.close
              return [
                _c(
                  "h5",
                  { staticClass: "txt-body txt-muted txt-primary" },
                  [
                    _vm._t("subtitle"),
                    _vm._v("\n      " + _vm._s(_vm.subtitle) + "\n    ")
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    staticClass: "close",
                    attrs: { variant: "icon primary", size: "sm" },
                    on: {
                      click: function($event) {
                        return close()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "icon-sm",
                        attrs: { width: "24", viewBox: "0 0 24 24" }
                      },
                      [_c("use", { attrs: { "xlink:href": "#icon-cross" } })]
                    )
                  ]
                )
              ]
            }
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.$store.getters["isMobile"]
            ? "txt-body"
            : "txt-heading-small mb-0"
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _vm._v(" "),
      _vm.description
        ? _c("p", { staticClass: "txt-body txt-muted mt-4 mb-2" }, [
            _vm._v(_vm._s(_vm.description))
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "template",
        { slot: "modal-footer" },
        [
          _c(
            "b-btn",
            {
              attrs: { variant: "secondary", disabled: _vm.processing },
              on: {
                click: function($event) {
                  return _vm.$emit("reject")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.rejectLabel) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-btn",
            {
              attrs: {
                disabled: _vm.processing || _vm.disabledAccept,
                variant: "primary"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("accept")
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.acceptLabel) + "\n    ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }