import Vue from 'vue';
import Folder from '@app/models/Folder';
import Playlist from '@app/models/Playlist';
import moment from 'moment';
import get from 'lodash/get';

export const buildApiUrl = (url, options) => {
  if (options) {
    url += '?';
    Object.entries(options).forEach(([key, value], index) => {
      if (index !== 0) url += '&';
      url += `${key}=${value}`;
    });
  }
  return url;
};

export const set = (target, property, value) => {
  let properties = target,
      parentProperties = target,
      parentKey = '';


  if ( property.split('.').length > 1 ) {
    let keys = property.split('.');
    keys.forEach((key, index) => {
      if ( index < property.split('.').length - 1 ) {
        if ( !properties ) {
          Vue.set( parentProperties, parentKey, {} );
          properties = parentProperties[parentKey];
        }
        if ( !properties.hasOwnProperty(key) ) {
          Vue.set( properties, key, {} );
        }
        parentProperties = properties;
        parentKey = key;
        properties = properties[key];
      } else {
        Vue.set( properties, key, value );
      }
    });
  } else {
    Vue.set( properties, property, value );
  }

  if (target.$update) {
    target.$update(target)
  }

  return target;
};

export const clearFileInput = (ctrl) => {
  try {
    ctrl.value = null;
  } catch(ex) { }
  if (ctrl.value) {
    ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
  }
};

export const promiseTimeout = (ms, promise) => {
  let id;
  const timeout = new Promise((resolve, reject) => {
    id = setTimeout(() => {
      reject('Timed out in ' + ms + 'ms.')
    }, ms)
  })

  return Promise.race([
    promise,
    timeout
  ]).then((result) => {
    clearTimeout(id);
    return result;
  })
}

export const getFileDuration = (file) => {
  return promiseTimeout(1000, new Promise(function(resolve, reject) {
    let vid = document.createElement('video');
    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = function() {
      resolve(this.duration);
    };
    // create url to use as the src of the video
    vid.src = URL.createObjectURL(file);
  })).catch(e => {
    console.error(e);
    return null;
  });
};

export const humanReadableDuration = seconds => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor(seconds % 3600 / 60);
  const s = Math.floor(seconds % 3600 % 60);
  if (!seconds)
    return '0s';
  return h < 1
    ? `${m === 0 ? '' : m+'m'}${s === 0 ? '' : ' '+s+'s'}`
    : `${h}h${m === 0 ? '' : ' '+m+'m'}`;
};

export const mediaFilters = {
  sources: [
    {
      value: 'facebook',
      label: 'Facebook'
    },
    {
      value: 'vimeo',
      label: 'Vimeo'
    },
    {
      value: 'zoom',
      label: 'Zoom'
    },
    {
      value: 'local',
      label: 'Uploads'
    },
    {
      value: 'podcast',
      label: 'Podcasts'
    },
    {
      value: 'dropbox',
      label: 'Dropbox'
    },
    {
      value: 'googledrive',
      label: 'Google Drive'
    },
    {
      value: 'wistia',
      label: 'Wistia'
    },
  ],
  status: [
    {
      value: 'processed',
      label: 'Processed'
    },
    {
      value: 'pending',
      label: 'Pending'
    },
    {
      value: 'failed',
      label: 'Failed'
    },
    {
      value: 'raw',
      label: 'Not processed'
    },
  ],
  duration: [
    {
      value: '<20',
      label: 'Short (< 20 minutes)'
    },
    {
      value: '>30',
      label: 'Long (> 30 minutes)'
    },
  ],
  date: [
    {
      value: 'day',
      label: 'Today'
    },
    {
      value: 'week',
      label: 'Last week'
    },
    {
      value: 'month',
      label: 'Last month'
    },
    {
      value: 'year',
      label: 'Last year'
    },
  ]
};

export const getActiveFilters = filters => {
  console.log('filters: ', filters)
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null || Array.isArray(filters[key]) && !filters[key].length) {
      delete filters[key];
    }
    if (Array.isArray(filters[key])) {
      let arrayString = '';
      filters[key].forEach((opt, index) => {
        arrayString += opt;
        if (index !== filters[key].length - 1) {
          arrayString += ','
        }
      });
      filters[key] = arrayString;
    }
  });
  return filters;
};
const getFilterLabel = (type, value) => {
  if ( type === 'folders' ) {
    const folder = Folder.query().where('hash', value).first();
    if (folder)
      return folder.title;
    Folder.api().get(value);
    return '...';
  } else if ( type === 'playlists' ) {
    const playlist = Playlist.query().where('hash', value).first();
    if (playlist)
      return playlist.title;
    Playlist.api().get(value);
    return '...';
  } else if (type === 'tags') {
    return value;
  } else {
    return mediaFilters[type] && mediaFilters[type].find(f => f.value === value).label;
  }
};
export const getActiveFilterObjects = filters => {
  let filterObjects = [];
  Object.keys(filters).forEach((key) => {
    if (filters[key] === null || (Array.isArray(filters[key]) && !filters[key].length)) {
      delete filters[key];
    }
  });
  Object.entries(filters).forEach(([key, value]) => {
    value = Array.isArray(value) ? value : [value];
    value.forEach(val => {
      filterObjects.push({
        type: key,
        value: val,
        label: getFilterLabel(key, val)
      });
    });
  });
  return filterObjects;
};

export const addProfitWellScript = email => {
  if ( document.getElementById('profitwell-js') ) return;
  let newScript = document.createElement("script");
  newScript.setAttribute('id', 'profitwell-js');
  newScript.setAttribute('data-pw-auth', '774bf0eaee71b51157ec9abdeda6c096');

  let inlineScript = document.createTextNode(`(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
       a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
       s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
       })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
       profitwell('start', { 'user_email': '${email}' });`);
  newScript.appendChild(inlineScript);
  document.head.appendChild(newScript);
};

export const appCuesSetup = user => {
  if ( !window.Appcues ) return;
  let created_at = moment(user.created_at).unix(),
    plan = get(user, 'teams.[0].current_billing_plan', null),
    language = get(user, 'teams.[0].meta.settings.transcription_language.code', null);
  window.Appcues.identify(
    user.id, // unique, required
    {

      // recommended (optional) properties

      createdAt: created_at, // Unix timestamp of user signup date
      purchasedAd: null, // Unix timestamp of account purchase date (leave null if empty)
      planTier: plan, // Current user’s plan tier
      accountId: user.id, // Current user's account ID
      firstName: user.name, // current user's first name

      // additional suggestions

      email: user.email, // Current user's email
      language: language, // for multi-language applications
    }
  );
};
export const copyToClipboard = async str => {
  try {
    await navigator.clipboard.writeText(str);
  } catch (error) {
    console.error(error);
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
};

export const driftSetup = user => {
  if ( !window.drift || !user || !user.id) return;
  let created_at = moment(user.created_at).unix(),
      plan = get(user, 'teams.[0].current_billing_plan', null),
      language = get(user, 'teams.[0].meta.settings.transcription_language.code', null);
  drift.identify(`${user.id}`, {
    email: user.email,
    plan: plan ?? '',
    language: language,
    name: user.name,
    created_at: created_at
  });
};

export const timeBetween = (target, from) => {
  const localTarget = moment.utc(target).local();
  const fromDate = moment(from || new Date());
  const diff = moment.duration(localTarget.diff(fromDate));
  return {
    isDone: diff <= 0,
    fromNow: localTarget.fromNow(true),
    days: diff.asDays(),
    hours: diff.asHours(),
    mins: diff.asMinutes(),
    secs: diff.asSeconds()
  }
}
export const getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getExcerpt = (str, maxLen, separator = ' ') => {
  if (!str) return '';
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen));
};

export const embedResponsively = (frame, responsive = true) => {
  return (responsive)
    ? `<style>.searchie-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .searchie-container iframe, .searchie-container object, .searchie-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='searchie-container'>${frame}</div>`
    : frame;
};


export const trimString = (string, length) => {
  if(string.length > length) {
    return `${string.substring(0, length)}...`
  } else {
    return string
  }
}

export const prettyBites = (num) => {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  let exponent;
  let unit;
  let neg = num < 0;
  let units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
}


export const formatToUnits = (number, precision) => {
  const abbrev = ['', 'k', 'm', 'b', 't'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
  const suffix = abbrev[order];

  return number ? (number / Math.pow(10, order * 3)).toFixed(precision) + suffix : 0;
}

export const numberFormat = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const percentageFormat = (number) => {
  return `${number ? Math.round(number) !== number ? number.toFixed(2) : number : 0}%`
}

export const mediaQuery = (options, unit = 'px') => {
  let query = 'screen';
  if ( options.maxWidth ) query += ` and (max-width: ${options.maxWidth + unit})`;
  if ( options.minWidth ) query += ` and (min-width: ${options.minWidth + unit})`;
  if ( options.maxHeight ) query += ` and (max-height: ${options.maxHeight + unit})`;
  if ( options.minHeight ) query += ` and (min-height: ${options.minHeight + unit})`;
  return window.matchMedia(query).matches;
}

export const emailFormatter = (value) => {
  if (!value) return value;
  let parts = value.split('@');
  if (parts.length !== 2) return value;
  parts[1] = parts[1].toLowerCase();
  return parts.join('@');
}


export const isValidUrl = (str) => {
  const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const manualSort = (data, property) => {
  if ( !property ) property = 'position';
  const emojiRegex = /\p{Extended_Pictographic}/u

  let hasEmoji = []
  let sortedList

  let sortSymbol = ''
  if(property[0] === '-') {
    sortSymbol = property[0]
    property = property.substr(1);
  }

  hasEmoji = data.filter(d => emojiRegex.test(d[property]))
  data = data.filter(d => !emojiRegex.test(d[property]))

  // if drag and drop option is enabled, reject the sort logic below
  if(property === 'position') return data

  const localSort = (a,b) => {
    return a[property].localeCompare(b[property], undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  }

  if(sortSymbol === '') {
    data.sort(localSort)
    if(hasEmoji) hasEmoji.sort(localSort)

    sortedList = [...hasEmoji, ...data]
  } else {
    data.sort(localSort).reverse()
    if(hasEmoji) hasEmoji.sort(localSort).reverse()

    sortedList = [...data, ...hasEmoji]
  }

  return sortedList
}
