<template>
  <upgrade-promo 
    v-if="$cantView('audience')"
    description="Invite your people to Hubs, segment them into functional groups, award achievements, and so much more!"
    :link="redirect"
  >
    <template #title>
      Manage Your entire Audience all in <span>One Place </span>
    </template>
    <template #image>
      <img class="illustration-audience" :src="`${$assetUrl}/images/hubs/illustration-audience-upgrade.png`" alt=""></img>
      <img class="avatar-top" :src="`${$assetUrl}/images/hubs/avatar-top.png`" alt=""></img>
    </template>
  </upgrade-promo>
  <div v-else class="audience-main">
    <audience-header
      :loading="isLoading"
      @reset="reset"
      :no-audience="!audiences.length && !isLoading && !hasFilter && ((segment && !segment.conditionsCount) || !segment)">
      <p :class="{'invisible no-events' : isRouteChanged || (!isLoading && !audiences.length)}" class="txt-body txt-muted px-3 mb-3">Your Audience contains everyone who has accessed your content in the past or may access it in the future. <br> Add people to your audience one-by-one or import a list of people with a CSV file.
        <a class="txt-primary btn-link-text" target="_blank" href="https://help.searchie.io/the-audiences-tab">Learn more.</a>
      </p>
      <audience-table-head
        :count-total="total"
        :count-selected="selectedData.length"
        :loading="isLoading"
        @filter-changed="getAudienceApi"
        @selectAll="selectAll($event)"
        @sync="search(true)"
        v-if="(hasFilter || (segment && segment.conditionsCount)) || audiences.length && !isRouteChanged || isLoading"
        :empty="!audiences.length && !isLoading"
      />
    </audience-header>

    <div id="grid-wrapper" v-if="(audiences.length || isLoading) && !isRouteChanged">
      <ag-grid-vue
        class="audience-table ag-table ph-no-capture"
        :class="{disabled: isLoading}"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :frameworkComponents="frameworkComponents"
        :rowData="audiences"
        @sortChanged="sortChanged"
        @row-clicked="selectRow($event, 'Show')"
        @grid-ready="onGridReady"
        @grid-size-changed="onGridSizeChanged"
        @selection-changed="onSelectionChanged"
      />
    </div>
    <transition name="fade" appear>
      <div v-if="isLoading || isRouteChanged" key="loading">
        <div v-for="elem in 20" class="audience-skeleton">
          <span class="skeleton-checkbox"></span>
          <span class="skeleton-avatar"></span>
          <div class="skeleton-lines">
            <i class="skeleton-title"></i>
            <i class="skeleton-subtitle"></i>
          </div>
        </div>
      </div>
      <div class="side-panel-group-empty txt-center" key="empty" v-else-if="!isLoading && !isRouteChanged && !audiences.length">
        <template v-if="(hasFilter || (segment && segment.conditionsCount))">
          <illustration-dog-digging/>
          <h4 class="txt-heading txt-bold mt-4">No results found.</h4>
          <p class="txt-body txt-muted">Try again with another search term or change filters.</p>
        </template>
        <template v-else-if="!hasFilter && !segment">
          <illustration-dog-bowl/>
          <h4 class="txt-heading txt-bold mt-4 mb-2">Your Audience list is empty.</h4>
          <span class="txt-body txt-muted">Your Audience contains everyone who has accessed your content in the past or may access it in the future. <br> Add people to your audience one-by-one or import a list of people with a CSV file.
            <a class="txt-primary btn-link-text" target="_blank" href="https://help.searchie.io/en/articles/5485696-the-audiences-tab">Learn more.</a>
          </span>
          <b-btn variant="primary mt-4" @click.stop="openPersonDialog">
            <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-user-add"></use></svg>
            <b>Add Person</b>
          </b-btn>
        </template>
      </div>
    </transition>

    <pagination
      v-if="!isLoading && !isRouteChanged && audiences.length"
      :count-total="total"
      :current-page="page"
      :pages="lastPage"
      :per-page-size="5"
      @switch-page="currentPage"
    />
    
    <person-dialog @close="clearFocus"/>
    <export-dialog />
    <segment-dialog />
    <delete-dialog
      v-if="audience"
      id="audience-delete-dialog"
      title="Delete Person"
      :processing="isDeleting"
      @accept="deleteAudience"
    >
      <div class="txt-heading-small">Are you sure you want to delete <br/><b>{{ audience.name }}</b>?</div>
    </delete-dialog>
    <audience-segment-dialogs />
    <bulk-dialog />
    <exit-dialog
      id="exit-person-dialog"
      :subtitle="exitDialogSubtitle"
      title="Save changes before exit?"
      description="Unsaved data will be lost."
      accept-label="Save & Exit"
      reject-label="Exit"
      @reject="rejectPersonSettingsExit"
      @accept="acceptPersonSettingsExit"
    />
    <exit-dialog
      id="audience-bulk-dialog"
      title="Would you like to apply changes before leaving?"
      subtitle=""
      description="Unapplied changes will be lost."
      accept-label="Apply & Close"
      reject-label="Close"
      @reject="rejectAudienceBulk"
      @accept="acceptAudienceBulk"
    >
      <template v-slot:subtitle><b class="txt-primary">Apply Edits?</b></template>
    </exit-dialog>
    <delete-dialog
      v-if="exportItem"
      id="export-delete-dialog"
      title="Delete Export?"
      :resource-name="exportItem.title"
      :processing="isDeleting"
      @accept="deleteExport"
    />
    <delete-dialog
      v-if="selectedAudience"
      id="bulk-delete-dialog"
      title="Remove people?"
      :processing="isDeleting"
      @accept="deleteBulkAudience"
    >
    <!-- <div class="txt-heading-small">Keep this the same as it currently is, no updates needed.</div> -->
      <div class="txt-heading-small">Are you sure you want to remove <b>{{selectedAudience.length}} {{selectedAudience.length > 1 ? 'people' : 'person'}}</b> from your Audience?</div>
    </delete-dialog>
    <rename-dialog
      v-if="exportItem"
      id="export-rename-dialog"
      title="Rename"
      v-model="exportItem.title"
      @accept="renameExport"
      :processing="renameProcessing"
      placeholder="Enter new title here"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { AgGridVue } from 'ag-grid-vue'
  import rootVue from "@app2/app";
  import eventBus from '@app2/core/eventBus'
  import grid from '@app2/modules/Audience/Helpers/grid'
  import Pagination from '@app2/core/Components/Pagination'
  import Segment from "@app2/models/Segment";

  import personRouter from "@app2/modules/Audience/Person/person.router"

  import get from "lodash/get";
  import AudienceHeader from "@app2/modules/Audience/Listing/Components/Header";
  import SegmentDialog from "@app2/modules/Audience/Segment/App";
  import PersonDialog from "@app2/modules/Audience/Person/App";
  import ExportDialog from "@app2/modules/Audience/Export/App";
  import BulkDialog from "@app2/modules/Audience/Bulk/App";
  import DeleteDialog from '@app2/core/Components/Common/Modals/DeleteDialog'
  import ExitDialog from "@app2/core/Components/Common/Modals/ExitDialog";
  import RenameDialog from "@app2/core/Components/Common/Modals/RenameDialog";
  import IllustrationDogBowl from "@illustrations/DogBowl";
  import IllustrationDogDigging from "@illustrations/DogDigging";

  import settingsCellRenderer from "@app2/modules/Audience/Helpers/settingsCellRenderer";
  import headerCellRenderer from "@app2/modules/Audience/Helpers/headerCellRenderer";
  import AudienceTableHead from "@app2/modules/Audience/Listing/Components/TableHead";
  import AudienceSegmentDialogs from "@app2/modules/Audience/Segment/Components/Dialogs";
  import router from "@app2/modules/Audience/Segment/segment.router";
  import axios from "@app2/api/axios";
  import UpgradePromo from '@app2/core/Components/UpgradePromo.vue'

  export default {
    name: 'Audience',
    metaInfo() {
      return {
        title: 'Audience',
      }
    },
    mixins: [grid],
    data() {
      return {
        columnDefs: null,
        frameworkComponents: null,
        defaultColDef: {
          editable: false,
          sortable: true,
          flex: 1,
          minWidth: 100,
          filter: true,
          resizable: false
        },
        selectingAll: false,
        selectionChange: false,
        selectedData: [],
        gridApi: null,
        gridColumnApi: null,
        gridOptions: {
          rowSelection: 'multiple',
          suppressCellSelection: true,
          navigateToNextCell: this.navigateToNextCell,
          suppressRowClickSelection: true,
          headerHeight: 48,
          rowDeselection: true,
          rowHeight: 84,
          domLayout: 'autoHeight'
        },
        sdk: "https://intercom.help/searchie/en/articles/5256393-track-audience-activity-on-your-website",
        hub: "https://intercom.help/searchie/en/articles/5256429-invite-your-audience-to-your-private-hub",
        isDeleting: false,
        exportItem: null,
        renameProcessing: false,
        currentActivePage: null,
        isRouteChanged: false,
        redirect: "https://help.searchie.io/audience",
      }
    },
    components: {
      IllustrationDogBowl,
      IllustrationDogDigging,
      AudienceSegmentDialogs,
      AudienceTableHead,
      AudienceHeader,
      AgGridVue,
      Pagination,
      SegmentDialog,
      PersonDialog,
      settingsCellRenderer,
      headerCellRenderer,
      ExportDialog,
      DeleteDialog,
      BulkDialog,
      ExitDialog,
      RenameDialog,
      UpgradePromo,
    },
    computed: {
      segment() {
        return Segment.find(this.$store.state.v2.audience.segment.currentSegment);
      },
      ...mapGetters({
        page: 'v2/audience/page',
        total: 'v2/audience/total',
        lastPage: 'v2/audience/lastPage',
        hasFilter: 'v2/audience/hasFilter',
        isLoading: 'v2/audience/isLoading',
        audiences: 'v2/audience/getAudiences',
        filterModule: 'filter/filterModule',
        audience: 'v2/audience/selectedRowData',
        exitDialogSubtitle: 'v2/audience/exitDialogSubtitle',
        selectedAudience: 'v2/audience/selectedAudience',
        isAllSelected: 'v2/audience/isAllSelected',
      })
    },
    beforeMount() {
      this.frameworkComponents = { agColumnHeader: 'headerCellRenderer' };
      this.columnDefs = [
        {
          headerName: '',
          checkboxSelection: true,
          headerCheckboxSelection: false,
          minWidth: 48,
          maxWidth: 48,
          // field: 'created_at',
          sortable: true,
          cellClass: 'pr-2'
        },
        {
          headerName: 'Name (ID)/Email',
          field: 'name',
          minWidth: 160,
          sortable: true,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: { innerRenderer: this.userNameRenderer },
          cellClass: 'pl-1'
        },
        {
          headerName: 'First seen',
          field: 'created_at',
          sortable: true,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: { innerRenderer: this.seenRenderer }
        },
        {
          headerName: 'Last seen',
          field: 'last_seen_at',
          sortable: true,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: { innerRenderer: this.seenRenderer }
        },
        {
          // headerComponentParams: { menuIcon: 'settings' },
          headerName: '',
          sortable: false,
          editable: false,
          cellRendererFramework: 'settingsCellRenderer',
          cellRendererParams: {
            clicked: (data, toRoute) => {
              if(toRoute !== 'toDelete') {
                this.selectRow(data.node, toRoute)
              } else {
                this.setToDeleteAudience(data)
              }
            }
          },
          type: 'rightAligned'
        },
      ]
    },

    methods: {
      ...mapActions({
        search: 'v2/audience/segment/search',
      }),
      openPersonDialog() {
        personRouter.push({name: 'Create'}).catch(() => {})
        rootVue.$bvModal.show('person-editor-dialog');
      },
      toggleBodyClass(addRemoveClass, className) {
        let el = document.body;
        if (addRemoveClass === 'addClass') {
          el.classList.add(className);
        } else {
          el.classList.remove(className);
        }
      },
      selectAll(bool) {
        this.selectingAll = true;
        if ( bool ) {
          this.gridOptions.api.selectAll();
        } else {
          this.selectionChange = false;
          if ( !this.selectionChange ) {
            this.gridOptions.api?.deselectAll();
          }
        }
        this.$store.commit('v2/audience/SET_SELECT_ALL', bool);
      },
      reset() {
        this.$store.commit('v2/audience/segment/SET_SEGMENT', null);
        this.$store.commit('v2/audience/segment/SET_PREVIEW', null);
        this.$store.commit('v2/audience/SET_PAGE', 1)
        this.$store.commit('v2/audience/segment/SET_SHOW_FILTERS', false);
        this.$store.commit('v2/audience/segment/SET_WITH_PARAMS', false);
        Segment.deleteAll();
        this.$store.dispatch('v2/audience/segment/resetNewSegment');
        router.push({name: 'Create'}).catch(() => {});
        this.$nextTick(() => {
          this.$store.dispatch('v2/audience/getAudiences')
        });
      },
      setToDeleteAudience(params) {
        this.$store.dispatch('v2/audience/setSelectedRowData', params.data)
        setTimeout(() => {
          rootVue.$bvModal.show('audience-delete-dialog');
        }, 200)
      },
      deleteAudience() {
        this.isDeleting = true
        axios.delete(`/api/audience/${this.audience.hash}`).then(() => {
          this.$toasted.show('User Removed from Audience.', { type: 'success' });
          this.$store.commit('v2/audience/REMOVE_PERSON', this.audience)
          this.$store.commit('v2/audience/person/SET_MODAL_STATE', false)
          this.isDeleting = false
          rootVue.$bvModal.hide('audience-delete-dialog');
          this.clearFocus();
        }).catch(() => {
          this.$toasted.show('Error while saving changes', { type: 'danger' });
        })
      },
      async deleteExport() {
        this.isDeleting = true
        try {
          await axios.delete(`/api/audience/exports/${this.exportItem.hash}`);
          this.$parent.$forceUpdate();
          this.$toasted.show('Export deleted successfully!', { type: 'success' });
          rootVue.$bvModal.hide('export-delete-dialog');

          this.isDeleting = false
          eventBus.$emit('delete-export-success', this.exportItem)
        } catch (e) {
          this.$toasted.show('Could not delete export, something went wrong. :(', { type: 'error' });
          throw e;
        }
      },
      deleteBulkAudience() {
        this.isDeleting = true
        const audiences = this.selectedAudience.map(d => d.id)
        axios.delete(`/api/audience/bulk`, { params: { audiences } }).then(() => {
          this.$store.commit('v2/audience/UPDATE_TOTAL_COUNT')
          this.$store.commit('v2/audience/REMOVE_AUDIENCE', audiences)
          rootVue.$bvModal.hide('bulk-delete-dialog');
          this.$toasted.show(`${audiences.length} ${audiences.length > 1 ? 'people' : 'person'} removed from audience.`, { type: 'success' });
          this.isDeleting = false
          if(this.$route.query.page) {
            if(parseInt(this.$route.query.page) === this.lastPage && (this.selectedAudience.length === 25 || this.isAllSelected)) {
              let currentActivePage = parseInt(this.$route.query.page) - 1
              this.$store.commit('v2/audience/SET_PAGE', currentActivePage)
            }
          }
          this.$store.commit('v2/audience/SET_SELECTED_AUDIENCE', [])
          this.selectedData = []
          this.$store.commit('v2/audience/SET_SELECT_ALL', false);
          setTimeout(() => {
            this.getAudienceApi()
          }, 500)
        }).catch((e) => {
          this.$toasted.show('Error while saving changes', { type: 'error' });
        })
      },
      async renameExport() {
        this.renameProcessing = true
        try {
          await axios.put(`/api/audience/exports/${this.exportItem.hash}`, this.exportItem);
          this.$toasted.show('Export renamed successfully!', { type: 'success' });
          rootVue.$bvModal.hide('export-rename-dialog');
          this.renameProcessing = false
          eventBus.$emit('rename-export-success', this.exportItem)
        } catch (e) {
          this.$toasted.show('Could not delete export, something went wrong. :(', { type: 'error' });
          throw e;
        }
      },
      selectRow(params, toRoute) {
        personRouter.push({ name: '/' }).catch(() => {}) // re-trigger mounted for activity
        this.$nextTick(() => {
          this.$store.commit('v2/audience/SET_FROM_HOME', true)
          this.$store.dispatch('v2/audience/getAudienceAnalytics', params.data.hash)
          this.$store.dispatch('v2/audience/getAudienceActivity', params.data.hash)
          this.$store.dispatch('v2/audience/getAudienceAttributes', params.data.hash)
          this.$store.dispatch('v2/audience/getAudienceHubAccess', params.data.hash)
          this.$store.dispatch('v2/audience/setSelectedRowData', params.data)
          personRouter.push({name: toRoute, params: {person: params.data}}).catch(() => {})
          rootVue.$bvModal.show('person-editor-dialog');
        })
      },
      currentPage(page) {
        this.$store.commit('v2/audience/SET_PAGE', page)
        this.$store.dispatch('v2/audience/getAudiences')
        this.$store.commit('v2/audience/SET_SELECT_ALL', false);
        this.selectedData = [];
      },
      onSelectionChanged(e) {
        this.selectedData = this.gridApi.getSelectedRows();
        // this.selectedData = this.selectedData.map((sd) => {
        //   axios.get(`/api/audience/${sd.hash}/hubs`, {params: {include: ['price']}}).then(({data}) => {
        //     sd.hubs = data.data
        //   })
        //   return sd
        // })
        this.$store.commit('v2/audience/SET_SELECTED_AUDIENCE', this.selectedData)
        if ( !this.selectingAll ) {
          this.selectionChange = true;
          this.$store.commit('v2/audience/SET_SELECT_ALL', false);
        }
        this.selectingAll = false;
        this.clearFocus();
      },
      async getAudienceApi() {
        if(!Object.keys(this.$route.query).length) {
          this.$store.commit('v2/audience/RESET_QUERY_PARAMS')
        }
        await this.$store.dispatch('v2/audience/getAudiences')
        this.$store.dispatch('v2/audience/getHubs')
      },
      async initialSearch(advanced = false) {
        if ( advanced ) {
          this.gridOptions.rowHeight = 84
        } else {
          if (this.$route.query.page)
            this.$store.commit('v2/audience/SET_PAGE', +this.$route.query.page)
          if (Object.keys(this.$route.query).length !== 0) {
            if (this.$route.query.controller !== 'created_before' || this.$route.query.controller !== 'created_after' || this.$route.query.controller !== 'created_between') {
              this.$store.commit('v2/audience/SET_FILTER_QUERY_PARAMS', this.$route.query[`filter[${this.$route.query.controller}]`])
            }
            this.$store.dispatch('v2/audience/setAudienceTypeQueryParams', this.$route.query.isPerson)
            this.$store.commit('v2/audience/SET_PAGE', this.$route.query.page)

            if (this.$route.query.controller) {
              this.$store.commit('filter/SET_CONTROLLER_NAME', this.$route.query.controller)
            } else {
              this.$store.commit('filter/SET_CONTROLLER_NAME', 'email')
            }
          } else {
            this.$store.commit('filter/SET_CONTROLLER_NAME', 'email')
            this.$store.commit('v2/audience/SET_PAGE', 1)
          }
          this.gridOptions.rowHeight = 84
          await this.getAudienceApi()
        }
      },
      updateRow(data) {
        let index = this.audiences.findIndex(a => a.id == data.id)
        let rowNode = this.gridApi.getRowNode(index)
        rowNode.setData(data)
        this.gridApi.refreshCells({force: true, rowNodes: [rowNode]})
      },
      acceptPersonSettingsExit() {
        this.$store.commit('v2/audience/person/SET_CAN_CLOSE_MODAL', true)
        this.$store.commit('v2/audience/person/SET_MODAL_STATE', false)
        eventBus.$emit('save-profile-settings')
        rootVue.$bvModal.hide('exit-person-dialog')
      },
      rejectPersonSettingsExit() {
        this.$store.commit('v2/audience/person/SET_CAN_CLOSE_MODAL', true)
        this.$store.commit('v2/audience/person/SET_MODAL_STATE', false)
        eventBus.$emit('reset-profile-settings')
        rootVue.$bvModal.hide('exit-person-dialog')
      },
      rejectAudienceBulk() {
        eventBus.$emit('reset-bulk-edit-settings')
        rootVue.$bvModal.hide('audience-bulk-dialog')
      },
      acceptAudienceBulk() {
        eventBus.$emit('save-bulk-edit-settings')
        rootVue.$bvModal.hide('audience-bulk-dialog')
      },
      clearFocus() {
        this.gridApi.clearFocusedCell();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.isRouteChanged = true;
      this.$store.dispatch('v2/audience/segment/resetNewSegment', true);
      this.$store.commit('v2/audience/segment/SET_SEGMENT', null);
      this.$store.commit('v2/audience/segment/SET_PREVIEW', null);
      this.$store.commit('v2/audience/UPDATE_LOADING_STATE', false)
      this.$store.commit('v2/audience/UNSET_AUDIENCES')
      this.$store.commit('v2/audience/UNSET_SELECTED_ROW_DATA')
      this.$store.commit('v2/audience/RESET_QUERY_PARAMS')
      this.$store.commit('v2/audience/SET_PAGE', 1)
      this.$store.commit('v2/audience/segment/SET_SHOW_FILTERS', false);
      this.$store.commit('v2/audience/segment/SET_ATTRIBUTES', []);
      next();
    },
    async mounted() {
      this.$store.commit('v2/audience/UPDATE_LOADING_STATE', true);
      if ( this.$route.query.hub ) {
        this.$store.commit('v2/audience/segment/SET_WITH_PARAMS', true);
        const { data } = await axios.get(`/api/internal/v2/hubs/${this.$route.query.hub}/`);
        await this.$store.dispatch('v2/audience/segment/newSegment', {
          hub: parseInt(data.id),
        });
        await this.$store.dispatch('v2/audience/segment/setPreview', true);
        this.$store.commit('v2/audience/segment/SET_SHOW_FILTERS', true);
      }
      if ( this.$route.query.tag ) {
        this.$store.commit('v2/audience/segment/SET_WITH_PARAMS', true);
        await this.$store.dispatch('v2/audience/segment/newSegment', {
          tags: [this.$route.query.tag],
        });
        await this.$store.dispatch('v2/audience/segment/setPreview', true);
        this.$store.commit('v2/audience/segment/SET_SHOW_FILTERS', true);
      }
      this.$nextTick(async () => {
        await this.initialSearch( get(this.$route, 'params.advanced', false) );

        eventBus.$on('update-audience', (data) => {
          this.updateRow(data)
        })
        eventBus.$on('set-export-item', (data) => {
          this.exportItem = JSON.parse(JSON.stringify(data))
        })
        eventBus.$on('remove-hub-access', hubs => {
          hubs.forEach(hub => {
            this.selectedData.forEach(audience => {
              audience.hubs = audience.hubs.filter(h => h.id !== hub.id)

              this.updateRow(audience)
            })
          })
        })

        if(this.$route.params && this.$route.params.toRoute) {
          const formatPerson = {
            data: { ...this.$route.params.person }
          }
          this.selectRow(formatPerson, this.$route.params.toRoute)
        }
      });
      this.toggleBodyClass('addClass', 'audience-body');
    },
    destroyed() {
      this.toggleBodyClass('removeClass', 'audience-body');
      this.$store.commit('v2/audience/SET_SELECT_ALL', false);
    },
  }
</script>
