import Router from 'vue-router';

const router = new Router({
  mode: 'abstract',
  name: 'audience-import',
  routes: [
    {
      path: '/',
      name: 'ImportIndex',
      component: require('@app2/modules/Audience/Import/Pages/Index').default,
      meta: {
        title: 'Import',
      }
    },
    {
      path: '/recent-imports',
      name: 'RecentImports',
      component: require('@app2/modules/Audience/Import/Pages/RecentImports').default
    },
    {
      path: '/upload-step-1',
      name: 'UploadStep1',
      component: require('@app2/modules/Audience/Import/Components/Steps/SelectTags').default
    },
    {
      path: '/upload-step-2',
      name: 'UploadStep2',
      component: require('@app2/modules/Audience/Import/Components/Steps/MatchColumns').default
    },
    {
      path: '/upload-step-3',
      name: 'UploadStep3',
      component: require('@app2/modules/Audience/Import/Components/Steps/Review').default
    },

    {
      path: '/copy-step-1',
      name: 'CopyStep1',
      component: require('@app2/modules/Audience/Import/Components/Steps/Copy&Paste').default
    },
    {
      path: '/copy-step-2',
      name: 'CopyStep2',
      component: require('@app2/modules/Audience/Import/Components/Steps/SelectTags').default
    },
    {
      path: '/copy-step-3',
      name: 'CopyStep3',
      component: require('@app2/modules/Audience/Import/Components/Steps/MatchColumns').default
    },
    {
      path: '/copy-step-4',
      name: 'CopyStep4',
      component: require('@app2/modules/Audience/Import/Components/Steps/Review').default
    },
  ]
});

export default router;
